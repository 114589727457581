import React, {Component} from 'react';
import '../estilo.css';
import { ToastStore} from 'react-toasts';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
// import ReactDOM from 'react-dom';
import {CabecalhoModal} from '../CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';

import AnotacaoCard from '../widget/AnotacaoCard';
import api from '../api';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export class UsuarioAnotacaoModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            animation: "zoom",
            height_modal:0,
            scrollOffset:0,
            list:[],
            anotacao_id_list_str:"",
            carregandoMaisAnotacoes:false,
            ultimo_anotacao_id:0,
            nenhuma_anotacao:false,
            anotacao_selecionada: {},
        };
        this.anchors = {};
      }
    //------------------------
    async componentDidMount() {
        if(this.state.list.length===0)
            this.getAnotacoes();

        this.props.Modals.setState({AnotacaoModal: this});
      }
    //--------------------
    toastStore2 = () => {
        ToastStore.success("teste2222");
    };
  //-------------------------
    onComplete = () => {
        this.setState({clockVisibility:"visible"});
    };
    //-------------------------
    expand = () => {
         this.toastStore2();
        // this.setState({dialogClassNn:"temperatura-modal2"});
    };
    //------------------------------------------
    setGrupoId = (grupo)=>{
        let listaVazia = [];
        this.fecharModal();
    }
    //-------------------
    minimizarModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "slideLeft"});
        this.props.Modals.closeUsuarioAnotacaoModal();

        if(this.props.Modals.state.GridColetaEntrega != null)
          this.props.Modals.state.GridColetaEntrega.visibleFilterrow();
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeUsuarioAnotacaoModal();

        // if(this.props.Modals.state.GridColetaEntrega != null)
        //     this.props.Modals.state.GridColetaEntrega.visibleFilterrow();
    };
    //---------close----------
    desativarModal = () =>{
        if(this.props.show === false)
            this.props.Modals.desativarUsuarioAnotacaoModal();
    };
    //-------------------------
    sequenciaUpdate = () => {
        this.props.Modals.setModalSelecionado("UsuarioAnotacaoModal");
    };
    //-------------------------
    getAnotacoes = async () => {
        let list = [];
        try {
            let body = new FormData()
            body.append('usuario_id', this.props.MapaKseg.state.usuario_id);
            body.append('token', localStorage.getItem('token'))

            var url = "/jornada_motorista_api/getUsuarioAnotacao";
            const response = await api.post(url, body);
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);           

                // ToastStore.success("resposta.length: "+ String(resposta.length))                 
                    for (var i = 0; i < resposta.length; i++) { 
                        // list.push(resposta[i]);
                        list.push({
                            usuario_anotacao_id: resposta[i].usuario_anotacao_id, 
                            usuario_id: resposta[i].usuario_id,
                            titulo_anotacao: resposta[i].titulo_anotacao,
                            data_anotacao: resposta[i].data_anotacao,
                            anotacao: resposta[i].anotacao,
                            ativo: resposta[i].ativo,
                            color: resposta[i].color,
                        });
                    };
                    this.setState({list:list});
                                
                if(resposta.length>0)    
                    this.setState({ultimo_anotacao_id: resposta[resposta.length-1].anotacao_id});
                else this.setState({nenhuma_anotacao:true});
            }       
            

        }catch (response){
            alert("catch!!");
        }
         finally {
            this.setState({loading:false});
         }
    };
    //-------------------------
    postAnotacao = async (titulo, anotacao, color_card) => {
        try {
            let body = new FormData()
            body.append('usuario_id', this.props.MapaKseg.state.usuario_id);
            body.append('titulo_anotacao', titulo);
            body.append('anotacao', anotacao);
            body.append('color', color_card);
            body.append('token', localStorage.getItem('token'));

            const response = await api.post('/jornada_motorista_api/insertUsuarioAnotacao', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                // console.log("resposta:");
                // console.log(resposta);  
                // alert(resposta);

                }       
            

        } finally {
            this.setState({loading:true});
            this.setState({list:[]});
            this.getAnotacoes();
        }
    };        
    //-------------------------
    postUpdateAnotacao = async (titulo, anotacao, color_card, usuario_anotacao_id) => {
        try {
            let body = new FormData()
            body.append('usuario_id', this.props.MapaKseg.state.usuario_id);
            body.append('usuario_anotacao_id', this.state.anotacao_selecionada.usuario_anotacao_id);
            body.append('titulo_anotacao', titulo);
            body.append('anotacao', anotacao);
            body.append('color', color_card);
            body.append('token', localStorage.getItem('token'));

            const response = await api.post('/jornada_motorista_api/updateUsuarioAnotacao', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                // console.log("resposta:");
                // console.log(resposta);  
                // alert(resposta);

                }       
            

        } finally {
            this.setState({loading:true});
            this.setState({list:[]});
            this.getAnotacoes();
        }
    };      
    //------------------------------------------------
    postDeleteAnotacao = async (usuario_anotacao_id) => {
        // this.props.MapaKseg.toast("post Remover Anotação "+String(this.state.anotacao_selecionada.usuario_anotacao_id));      
        try {
            let body = new FormData()
            body.append('usuario_anotacao_id', this.state.anotacao_selecionada.usuario_anotacao_id);
            body.append('token', localStorage.getItem('token'));
    
            const response = await api.post('/jornada_motorista_api/deleteUsuarioAnotacao', body);
            
            var responseJson = null
    
            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);

                if (resposta.mensagem == "ok"){
                    var list_aux = this.state.list.filter((item,index) =>{  
                        return item.usuario_anotacao_id !== this.state.anotacao_selecionada.usuario_anotacao_id;});
                    
                    this.setState({list : list_aux});  
                }
    
            }       
            
    
        } finally {}
    };            
    //-------------------------------------------------
    deleteAnotacao (anotacao){
        // ToastStore.success("postDeleteAnotacao "+String(usuario_anotacao_id));
        this.setState({anotacao_selecionada : anotacao});
    
        let texto = "Deseja Remover a Anotação "
        +"\n  "+String(anotacao.titulo_anotacao)
        +"\n ?";
        let imagem = require('../img/close_100dp.png');
        this.props.MapaKseg.state.Modals.showMensagemModal(this, "Remover Anotação", texto, true, imagem);
    }; 
    //-------------------------------------------------
    editarAnotacao (anotacao){
        // ToastStore.success("editarAnotacao "+String(anotacao.titulo_anotacao));
        this.setState({anotacao_selecionada : anotacao});
    
        this.props.Modals.showAnotacaoModal(this, "Anotação", true);
    };     
    //------------------------------------------------
    confirmarMensagem = (texto) => {
        if(texto ==="Remover Anotação"){
            this.postDeleteAnotacao(); 
        }
    }          
    //---------------------------------------------------------------
    showAnotacao= (e) => {   
        let anotacao_selecionada = this.state.anotacao_selecionada;
        anotacao_selecionada.usuario_anotacao_id = 0;
        anotacao_selecionada.anotacao = "";
        anotacao_selecionada.titulo_anotacao = "";
        anotacao_selecionada.color = "#E8D979";

        let today = new Date();
        let date_time = today.getDate() + "/" + (today.getMonth() + 1) + '/' + today.getFullYear() + '  '+today.getHours() + ':' + today.getMinutes();
        anotacao_selecionada.data_anotacao = date_time;

        this.setState({anotacao_selecionada: anotacao_selecionada },
            () => {  this.props.Modals.showAnotacaoModal(this, "Anotação", true);  }
        );             
    };     
    //-------------------------
    render(){
        return(
            <div className="drag3" >
                <Draggable   handle="strong"  >
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={1000}
                            height={700}
                            onAnimationEnd={()=>this.desativarModal()}
                            >
                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false} titulo={"Anotações"} Modal={this}/></strong>
                                <div className="padding_modal">
                                    {this.state.loading ?
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    :
                                        <div style={{height: '595px', overflow: 'hidden'}}>
                                            <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0" >

                                                {/* <Row xs={1} md={3} className="g-4">
                                                    {Array.from({ length: 5 }).map((_, idx) => (
                                                        <Col key={idx}>
                                                            <Card>
                                                                <Card.Body>
                                                                <Card.Title>Card title</Card.Title>
                                                                <Card.Text>
                                                                    This is a longer card with supporting text below as a natural
                                                                    lead-in to additional content. This content is a little bit
                                                                    longer.
                                                                </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    ))}
                                                </Row> */}

                                                <Row xs={1} md={3} className="g-4">
                                                    {this.state.list.map((anotacao, index) =>
                                                        <Col key={index}>
                                                            <AnotacaoCard value={anotacao} MapaKseg={this.props.MapaKseg} showMapIcon={false} UsuarioAnotacaoModal={this}/>
                                                        </Col>
                                                    )}
                                                </Row>

                                                    {this.state.nenhuma_anotacao?
                                                        <div className="texto_centro">
                                                            <text>Nenhuma Anotação!</text>
                                                        </div>
                                                        :
                                                        <div className="texto_centro">
                                                            <Button variant="light" onClick={() => this.getAnotacoes()}>
                                                                {this.state.carregandoMaisAnotacoes ?
                                                                    <div><Spinner animation="border" />CARREGANDO...</div>
                                                                : <div>CARREGAR MAIS</div>}
                                                            </Button>
                                                        </div>
                                                    }
                                            </CustomScroll>

                                            
                                            
                                        </div>
                                        
                                    }

                                    <button className="align_bottom_right_top"
                                        style={{backgroundColor: 'transparent', border: 0, outline: 'none'}}
                                        onClick={this.showAnotacao} >  
                                        <img src={require('../img/plus_circle_256px.png')} width="50px" />                                              
                                    </button>
                                </div>
                            </div>
                        </Rodal>
                    </div>
                </Draggable>
            </div>
        );
    }
}
