import React from 'react';

import 'devextreme/data/odata/store';
import DataGrid, { Column, Scrolling, Selection, MasterDetail, FilterRow, HeaderFilter, ColumnChooser } from 'devextreme-react/data-grid';
import ScrollView from 'devextreme-react/scroll-view';
import './estilo.css';
import {  Button as ButtonFab, lightColors } from 'react-floating-action-button'
import ContextMenu from 'devextreme-react/context-menu';
// import notify from 'devextreme/ui/notify';
import {GetComandosVeiculo} from './GetComandosVeiculo';
import GridVeiculoDetail from './GridVeiculoDetail.js';
import GetGruposVeiculo from './GetGruposVeiculo';
import SelectBox from 'devextreme-react/select-box';
import api from './api';
import { locale, loadMessages, formatMessage } from 'devextreme/localization';

import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';


export class GridVeiculos extends React.Component {
  constructor(props) {
    super(props);
    // this.onRowPrepared = this.logEvent.bind(this, 'RowInserting');
    this.onRowPrepared = this.onRowPrepared.bind(this); 

    this.state = {
      countNotificacao: 0,
      props: props,
      GridVeiculoDetail: null,
      items:[],
      load:true,
      filterStatus:'',
      gruposVei_list:[],
      grupos_nome_list:[],
      list:[],
      locale: this.getLocale(),
    }


  // -------------------------------
    this.dataGridRef = React.createRef();
 
    this.selectedRowsData = [];

    this.getSelectedData = () => {
        this.selectedRowsData = this.dataGrid.getSelectedRowsData();

        // ===== or when deferred selection is used =====
        this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            // Your code goes here
        });
    }
    //-----
    locale(this.state.locale);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onRowExpanding = this.onRowExpanding.bind(this);
    this.onValueChanged = this.onValueChanged.bind(this);

  }
  //---------------------------------------
  getLocale() {
    const storageLocale = sessionStorage.getItem('locale');
    return storageLocale != null ? storageLocale : 'pt';
  }
//------------------------------------------
  get dataGrid() {
    return this.dataGridRef.current.instance;
  }
//------------------------------------------
  onRowPrepared(e) {  
    // if (e.rowType !== "data")  
    //     return  
    // alert(JSONString);

    if(e.data !== undefined){
      if (e.data.velocidade === 0) {  
          e.rowElement.style.backgroundColor = "#F095A1";
      }else e.rowElement.style.backgroundColor = "#98d4b4";

      if(e.data.diff_minutes>=60)
        e.rowElement.style.backgroundColor = "#b3afaf";
    }  

    // if (e.rowType === "data")  
    //   e.data.selectRows([5, 10, 12], true);
     
  } 
  //---------------------------------------------------------------
  addMenuItems(e) {
    if (e.target == 'header') {
        // e.items can be undefined
        if (!e.items) e.items = [];

        // Add a custom menu item
        e.items.push({
            text: 'Log Column Caption',
            onItemClick: () => {
                // alert(e.column.caption);
                console.log(e.column.caption);
            }
        });
    }
  }
  //-------------------------------------------------------------------
  ItemTemplate (e) {
    return (
      <React.Fragment>
        <span className={ e.icon } />
        { e.items ? <span className="dx-icon-spinright" /> : null }
        { e.text }
      </React.Fragment>
    );
  }
  //---------------------------------------------------------------
  itemClick = (e) => {
    if (!e.itemData.items) {
      var selectedRowData = this.dataGrid.getSelectedRowsData(); 

      this.props.MapaKseg.setState({veiculoPosicao_selecionado: selectedRowData[0]});

      if(e.itemData.text === this.state.items[0].text){
        this.props.MapaKseg.state.Modals.showComandoGridModal(selectedRowData[0]);
        var instance = new GetComandosVeiculo(selectedRowData[0], this.props.MapaKseg); 
        instance.getComandosVei();   
      
      }else if(e.itemData.text === this.state.items[1].text){
        this.props.MapaKseg.state.Modals.showVeiculoInfoModal(selectedRowData[0]);
      
      }else if(e.itemData.text === this.state.items[2].text){
        window.open("https://maps.google.com?q="+String(selectedRowData[0].latitude)+","+String(selectedRowData[0].longitude) );
      
      }else if(e.itemData.text === this.state.items[3].text){
        this.props.MapaKseg.state.Modals.showObservacaoModal(this, "Anotação Veículo", selectedRowData[0].anotacao, true, 'observacao');

      }else if(e.itemData.text === this.state.items[4].text){
        this.exportDataGridToxlsx(" Excel");

      }else if(e.itemData.text === this.state.items[5].text){
        this.props.MapaKseg.state.Modals.showCompartilharRastreamentoModal(this, "Compartilhar", selectedRowData[0], true);

      }
    }
  }
  //--------------------------------------------------------------
  onSelectionChanged({ selectedRowsData }) {
    this.props.MapaKseg.ajustarZoom(selectedRowsData[0]);
  }
  //-------------------------------------------------------------
  onRowExpanding= (e) => {
    e.component.selectRows(e.key, false);
    // this.props.MapaKseg.ajustarZoom(selectedRowsData[0]);
  }
  //-----------------------------------------
  componentDidMount() {
    // var instance = new GridVeiculoDetail(this.props, this.props.MapaKseg);
    // this.setState({GridVeiculoDetail: instance});

    this.props.MapaKseg.setState({GridVeiculos: this});
    var items = this.state.items;
    items.push({ text: ' Enviar comando', icon: "dx-icon-export"  });

    if(this.props.MapaKseg.state.pessoa_id===1)
      items.push({ text: ' Cadastro Veículo', icon: "dx-icon-activefolder"  });

    items.push({ text: ' Ver no Google Maps', icon: "dx-icon-map"  });  
    items.push({ text: ' Anotação', icon: "dx-icon-paste"  });
    items.push({ text: ' Exportar Excel', icon: "dx-icon-exportxlsx" });
    items.push({ text: ' Compartilhar Rastreamento', icon: "dx-icon-share"  });

    var instance = new GetGruposVeiculo(this.props.MapaKseg.state.usuario_id, this, this.props.MapaKseg);
    instance.getGruposVei(); 

    this.setState({items: items, load:false});
    // this.props.MapaKseg.setState({GridVeiculos: this});
  };
  //----------------------------------
  confirmarObservacao(texto){  
    var selectedRowData = this.dataGrid.getSelectedRowsData();
    selectedRowData[0].anotacao = texto;
    this.postVeiculoAnotacao(selectedRowData, texto);
    this.dataGrid.refresh();
  };
  //--------------------------------------------------------------------
  carregarGrid = () => {
    // var instance = new GetPoligonos(this.props.MapaKseg, this.state.grupo_id, "", null);
    // instance.getPoligonos();
  }
  //------------------------------------------------------------------
  exportDataGridToxlsx = (e) =>  {
    this.setState({
      visible_datetime: false,
      visible_datetime_text: true,
      icone_visible: false}); 

    let today = new Date();
    let time = today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
    let date = today.getDate() + "-" + (today.getMonth() + 1) + '-' + today.getFullYear() + '  '+time;

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    let compo = this.dataGridRef.current.instance;
    let nomeArquivo = "Grid_Veículos "+date+".xlsx";

    exportDataGrid({
        // component: e.component,
        component: compo,
        worksheet: worksheet
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), nomeArquivo);
        this.setState({
          visible_datetime: true,
          visible_datetime_text: false,
          icone_visible: true});
        });
    });

  }
  //--------------------------------------------------------------
  postGrupoVeiculoAtual = async (usuario_id, grupo_id) => {  
    try {
        let body = new FormData();
        body.append('grupo_id', grupo_id);
        body.append('usuario_id', usuario_id);
        body.append('token', localStorage.getItem('token'));

        const response = await api.post('/login_api/setGrupoVeiculoAtual', body);            
        var responseJson = null
        if (response.data !== null  &&  response.status !== 403){
            responseJson = JSON.stringify(response.data)
            var req = { json: responseJson };
            var resposta = JSON.parse(req.json);      
            
            // this.props.MapaKseg.toast("Atualizado GrupoVeiculoAtual!");
        }

    } finally {

        }
  };
    //--------------------------------------------------------------
    postVeiculoAnotacao = async (selectedRowData, anotacao) => {  
      try {
          let body = new FormData();
          body.append('veiculo_anotacao_id', selectedRowData[0].veiculo_anotacao_id);
          body.append('veiculo_id', selectedRowData[0].veiculo_id);
          body.append('usuario_id', this.props.MapaKseg.state.usuario_id);
          body.append('anotacao', anotacao);
          body.append('token', localStorage.getItem('token'));
  
          const response = await api.post('/cadastro_api/postVeiculoAnotacao', body);            
          var responseJson = null
          if (response.data !== null  &&  response.status !== 403){
              responseJson = JSON.stringify(response.data)
              var req = { json: responseJson };
              var resposta = JSON.parse(req.json);      
              
              // this.props.MapaKseg.toast("Atualizado GrupoVeiculoAtual!");
          }
  
      } finally {
  
          }
    };
  //--------------------------------------------------------------------------------
  onValueChangedGrupo(grupo_id ) {
    var gruposVei_list = this.state.gruposVei_list;
    var list_aux = gruposVei_list.filter((item,index) =>{  
      return item.grupo_id === grupo_id;
    });
    
    if (list_aux.length > 0) this.setState({filterStatus: list_aux[0].nome});
  }
  //-------------------------------------------------------------------------------- 
    onValueChanged({ value }) {
      // console.log("value:");
      // console.log(value);
      // console.log(value.trim());
      // this.props.MapaKseg.toast(value.trim());

      var gruposVei_list = this.state.gruposVei_list;
      var list_aux = gruposVei_list.filter((item,index) =>{  
        return item.nome.trim() === value.trim();
      })
  
      // console.log("list_aux:");
      // console.log(list_aux);
  
      // if (value === 'All') {
      //   dataGrid.clearFilter();
      // } else {
      //   dataGrid.filter(['nome', '=', value]);
      // }
  
      this.setState({filterStatus: value});
      localStorage.setItem('grupo_id', list_aux[0].grupo_id);
      this.props.MapaKseg.setState({grupo_id: list_aux[0].grupo_id});
      this.postGrupoVeiculoAtual(this.props.MapaKseg.state.usuario_id, list_aux[0].grupo_id);
      this.props.MapaKseg.recarregarPosicoesClick();
    }
  //---------------------------------------------------------------
  render() {
    const onContextMenuPreparing = (e) =>{
        try{
          e.component.selectRows(e.row.key, false)
          var selectedRowData = this.dataGrid.getSelectedRowsData();
          this.props.MapaKseg.ajustarZoom(selectedRowData[0]);

          console.log(e.row.key);
          // alert(e.row.key);
        }catch (response){}

      // if (e.target !== "content") 
      //     return;
      // // e.items = [];
      // if(e.row){
      //   e.component.selectRows(e.row.key, false)
      //   var selectedRowData = this.dataGrid.getSelectedRowsData();
      //   this.props.MapaKseg.ajustarZoom(selectedRowData[0]);

      //   console.log(e.row.key);
      //   alert(e.row.key);
      // }
    }
    
    return (       
      this.state.load? "" :
          <div>
            {this.state.grupos_nome_list.length >1 ?
              <div>
                <div className="left-side">
                  <SelectBox
                    items={this.state.grupos_nome_list}
                    value={this.state.filterStatus}
                    onValueChanged={this.onValueChanged} 
                    />
                </div>
                <div className="right-sidee">
                  <div className="logo">
                    &nbsp;
                  </div>
                </div>
              </div>
            :""}

            <ContextMenu
                dataSource={this.state.items}
                width={180}
                target="#dtGrid"
                itemRender={this.ItemTemplate}
                onItemClick={this.itemClick} />

            {this.props.MapaKseg.state.list.length >0 ?
              this.props.MapaKseg.state.loadReadyList ?

              // <DataGrid
              //     id="dtGrid"
              //     ref={this.dataGridRef}
              //     dataSource={this.props.MapaKseg.state.list}
              //     showBorders={true}
              //     rowAlternationEnabled={true}
              //   //   onRowPrepared={this.onRowPrepared}
              //     columnAutoWidth={true}
              //     onContextMenuPreparing={onContextMenuPreparing}
              //     onSelectionChanged={this.onSelectionChanged}    
              //     height={this.props.height_grid}
              //   > 
              //     <Selection mode="single" /> 
              //     <Scrolling mode="virtual" />
              //     <HeaderFilter visible={true} />
              //     <FilterRow visible={true} />

              //     <Column dataField="recebimento_id" />
              //     <Column dataField="placa" width={250} />
              //     <Column caption= "Data" dataField="data_receb" dataType="datetime" format= 'dd/MM/yyyy HH:mm'/>
              //     <Column caption= "Data2" dataField="data_receb2" dataType="datetime"/>
              //     <Column caption= "Data3" dataField="data_receb" />
              //     <Column caption= "Data4" dataField="data_receb2" />
              //     <Column caption= "Data5" dataField="data_posicao2" />
              //     <Column caption= "Município" dataField="municipio"/>
              //     <Column dataField="estado"/>
              //     <Column dataField="velocidade2" caption= "velocidade" />  

              //   </DataGrid>                
                <DataGrid
                  id="dtGrid"
                  // keyExpr="veiculo_id"
                  // dataSource={this.props.list}
                  // dataSource={this.state.list}
                  height={this.props.MapaKseg.state.grid_size}
                  dataSource={this.props.MapaKseg.state.list}
                  showBorders={true}
                  onRowPrepared={this.onRowPrepared}
                  onContextMenuPreparing={onContextMenuPreparing}
                  ref={this.dataGridRef}
                  onSelectionChanged={this.onSelectionChanged}    
                  onRowExpanding={this.onRowExpanding}    
                  allowColumnResizing={true}
                  allowColumnReordering={true}
                  >
                    <Scrolling mode="virtual" />
                    <Selection mode="single" />
                    <ColumnChooser enabled={true} title="Escolha a Coluna"/>    
                    <HeaderFilter visible={true} />                    
                    {/* <FilterRow visible={true} /> */}
                    

                    <Column dataField="index" caption= "" width={50}/>
                    <Column dataField="placa" width={250} />

                    {/* <Column dataField="data_receb2" caption={"Data"} format="EE  dd/MM/yyyy HH:mm" dataType="datetime" /> */}
                    {/* <Column caption= "Data2" dataField="data_receb2" dataType="datetime"/> */}
                    {/* <Column dataField="data_posicao2" caption= "Data" /> */}
                    <Column dataField="data_receb2" caption="Data" format= 'dd/MM/yyyy HH:mm' dataType="datetime" />

                    <Column dataField="municipio" caption="Município" />
                    <Column dataField="estado"/>
                    <Column dataField="velocidade2" caption= "Velocidade" />
                    <Column dataField="versao_equipamento" caption= "Equipamento" visible={true}/>
                    <Column dataField="ignicao" caption= "Ignição" visible={false}/>
                    <Column dataField="saida1" caption= "Saída1" visible={false}/>
                    <Column dataField="motorista_nome" caption= "Motorista" visible={true}/>
                    <Column dataField="anotacao" caption= "Anotação" visible={true}/>  

                    <MasterDetail
                      enabled={true}
                      component={GridVeiculoDetail}        
                      MapaKseg={this.props.MapaKseg}      
                    />
                  
                </DataGrid>
                : ""
              :  // Um Grid vazio:
                <DataGrid showBorders={true}  >
                  <Selection mode="single" />
                  <Column dataField="recebimento_id" />
                  <Column dataField="placa" width={250} />
                  <Column dataField="data_receb" caption= "Data" dataType="date" format= 'dd/MM/yyyy HH:mm'/>
                  <Column dataField="data_receb" caption= "Data2" dataType="date"/>
                  <Column dataField="municipio"  caption= "Município"/>
                  <Column dataField="estado"/>
                  <Column dataField="velocidade2" caption= "Velocidade" />                
                  <Column dataField="versao_equipamento" caption= "Equipamento" visible={false}/>
                  <Column dataField="ignicao" caption= "Ignição"  visible={false}/>        
                  <Column dataField="motorista_nome" caption= "Motorista" visible={true}/>        
                  <Column dataField="anotacao" caption= "Anotação"  visible={true}/>  
                </DataGrid>
              }
          </div>     
    );
  }
}
