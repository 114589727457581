import  {Component} from 'react';
import api from './api';
import {ToastStore} from 'react-toasts';

export class PostComando extends Component{
    constructor(props, mapaKseg){
        super(props);
        this.state = {
            loading:true,
            mapaKseg:mapaKseg,
       }
    }
    //---------------------
    toastStore = (texto) => {
        ToastStore.success("teste: "+texto);
    };   
    //--------------------------------  
    postComando = async () => {  
        try {
            let body = new FormData();
            let currentTime = new Date().toLocaleString();  
            //  01/06/2020 03:00:52
            // yyyy-MM-dd HH:mm:ss
            currentTime = currentTime.substring(6,10)+'-'+currentTime.substring(3,5)+'-'+currentTime.substring(0,2)+' '+currentTime.substring(10,19);
            body.append('veiculo_id', this.state.mapaKseg.state.veiculoPosicao_selecionado.veiculo_id);
            body.append('pessoa_id', this.state.mapaKseg.state.pessoa_id);
            body.append('token', localStorage.getItem('token'));
            body.append('comando_id', this.props.comando_id);
            body.append('comando', this.props.comando);
            body.append('currentTime', currentTime);
            body.append('usuario_id', this.state.mapaKseg.state.usuario_id)
            body.append('usuario_logado', this.state.mapaKseg.props.login)
            body.append('versao_equipamento_id', this.props.versao_equipamento_id)
            body.append('tecnologia_id', this.state.mapaKseg.state.veiculoPosicao_selecionado.tecnologia_id)
            body.append('valor1', this.props.valor1)
            body.append('valor2', this.props.valor2)
            // console.log("  ***datetime: "+datetime)
            console.log("  ****** body shiittt ****** ");
            console.log("veiculo_id: "+this.state.mapaKseg.state.veiculoPosicao_selecionado.veiculo_id);
            console.log("pessoa_id: "+this.state.mapaKseg.state.pessoa_id);
            console.log(localStorage.getItem('token'));
            console.log("comando_id: "+this.props.comando_id);
            console.log("comando: "+this.props.comando);
            console.log("currentTime: "+currentTime);
            console.log("usuario_id: "+this.state.mapaKseg.state.usuario_id);
            console.log("login: "+this.state.mapaKseg.props.login);
            console.log("versao_equipamento_id: "+this.props.versao_equipamento_id);
            console.log("tecnologia_id: "+this.state.mapaKseg.state.veiculoPosicao_selecionado.tecnologia_id);

            const response = await api.post('/comando_api/comandoEnvio', body);            
            var responseJson = null
            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);      
                
                ToastStore.success("Comando enviado!");
            }

        } finally {
                // this.state.mapaKseg.setState({eventos_list: this.state.list});

            }
    };
    //--------------------------
    async componentDidMount() {
        // ToastStore.success("getPosicoesVeiculo componentDidMount()");
        this.postComando();
    };
}