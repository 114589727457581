import React, {Component} from 'react';
import {Modal, Image, Spinner, Button, Form, Row,Col,Card,Nav,InputGroup,FormControl} from 'react-bootstrap';
import './estilo.css';
import { Switch } from 'devextreme-react/switch';
import {CabecalhoModal} from './CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import api from './api';
import {VeiculoGruposGrid} from './datagrid/veiculo_grupos_grid';
import GetVeiculoGrupos from './services/get_veiculo_grupos';
import PostVeiculoCad from './services/post_veiculo_cad';
import GetMotoristas from './services/get_motoristas';

const MOTORISTA=1;

export class VeiculoInfoModal extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            animation: "zoom",
            list:[],
            list_pessoa:[],
            list_tipo_chip:[{tipo_chip_id:1, descricao: 'eseye'}, {tipo_chip_id:2, descricao: 'vivo'},{tipo_chip_id:3, descricao: 'algar'}],
            list_protocolo_tcp_udp:[{valor:true, descricao: 'TCP'}, {valor:false, descricao: 'UDP'}],
            loading:true,
            minimizar:false,
            editado:false,
            veiculo:null,
            
            disable_chip:true,
            disable_placa:true,
            disable_pessoa:true,   
            disable_tipo_chip:true,
            disable_protocolo_tcp_udp:true, 
            
            disable_pacote_dados_mensais:true,
            disable_placa_detalhes:true,
            disable_renavam:true,
            disable_ano_fabricacao:true,
            disable_marca_modelo:true,
            disable_cor_predominante:true,
            disable_chassi:true,

            grupos: [],      
            editados: {},
            gruposLoaded: false,
        };
      }
    //--------------------------
    async componentDidMount() {
        this.getVeiculoInformacao();

        let editados = {
            placa:false
            , chip:false
            , pessoa:false
            , grupos: false  };
        
        this.setState({editados: editados});

    };
    //-------------------
    minimizarModal = () =>{
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        this.props.Modals.closeVeiculoInfoModal();  
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeVeiculoInfoModal();
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarVeiculoInfoModal();
    };
    //----------------------------------------------RelPosicoesVeiModal--------------------------------------------------------------------
    showItemModal = (item) => {
        if (this.props.tipoChamadaLista===1){
            if(item===1)
                this.props.SideNavDrawer.showTempGridModal();
        }else if(this.props.tipoChamadaLista===2){
            if(item===1)
                this.props.SideNavDrawer.showRelPosicoesVeiModal();
            else if(item===2)
                this.props.SideNavDrawer.showRelEventosVeiModal();    
        }

        this.minimizarModal();
    }; 
    //----------------------------
    sequenciaUpdate = () => {
        this.props.SideNavDrawer.setModalSelecionado("ListaModal");
    };
    //-------------------------
    getVeiculoInformacao = async () => {
        try {
            let body = new FormData()
            body.append('veiculo_id', this.props.MapaKseg.state.veiculoPosicao_selecionado.veiculo_id);
            body.append('token', localStorage.getItem('token'));

            const response = await api.post('/cadastro_api/getVeiculoInformacao', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                var list = this.state.list;  
                var veiculo = {                              
                    veiculo_id : resposta[0].veiculo_id,          
                    placa : resposta[0].placa,                  
                    veiculo_externo : resposta[0].veiculo_externo,
                    pessoa_id : resposta[0].pessoa_id,                        
                    nome_social : resposta[0].nome_social,                        
                    rastreador: resposta[0].rastreador,
                    chip: resposta[0].chip,
                    veiculo_entrada_saida_id: resposta[0].veiculo_entrada_saida_id,
                    tipo_entrada_1_id: resposta[0].tipo_entrada_1_id,
                    entrada1 : resposta[0].entrada1,
                    tipo_entrada_2_id: resposta[0].tipo_entrada_2_id,
                    entrada2 : resposta[0].entrada2,
                    tipo_saida_1_id: resposta[0].tipo_saida_1_id,
                    saida1 : resposta[0].saida1,
                    tipo_saida_2_id: resposta[0].tipo_saida_2_id,
                    saida2 : resposta[0].saida2,
                    tipo_chip_id : resposta[0].tipo_chip_id,
                    tipo_chip : resposta[0].tipo_chip,
                    tcp : resposta[0].tcp,
                    protocolo_tcp_udp : "",
                    zip: resposta[0].zip,
                    pacote_dados_mensais: resposta[0].pacote_dados_mensais,

                    placa_detalhes: resposta[0].placa_detalhes,
                    renavam: resposta[0].renavam,
                    ano_fabricacao: resposta[0].ano_fabricacao,
                    marca_modelo: resposta[0].marca_modelo,
                    cor_predominante: resposta[0].cor_predominante,
                    chassi: resposta[0].chassi,

                    edit_entrada_saida:false,
                    edit_chip:false,
                    edit_placa:false,
                    edit_pessoa:false,
                    edit_tipo_chip: false,
                    edit_protocolo_tcp_udp: false,
                    edit_zip:false,
                    edit_motorista:false,

                    motorista_id : resposta[0].motorista_id,
                    motorista : resposta[0].motorista,

                    grupos:[]
                };
                
                if(veiculo.veiculo_entrada_saida_id===null)
                    veiculo.veiculo_entrada_saida_id=0;

                if(veiculo.tcp===true) veiculo.protocolo_tcp_udp = "TCP"
                else if(veiculo.tcp===false) veiculo.protocolo_tcp_udp = "UDP"

                this.setState({veiculo: veiculo}); 
                console.log("resposta:");
                console.log(resposta);
                // ------------------------
                var res = resposta[1];
                var tipo_entrada_saida ={};
                for (var i = 0; i < res.length; i++) {    
                        tipo_entrada_saida = {                              
                            tipo_entrada_saida_id : res[i].tipo_entrada_saida_id,          
                            descricao : res[i].descricao,                  
                            entrada : res[i].entrada,       
                        };
                        list.push(tipo_entrada_saida);   
                        this.setState({list: list});  
                    }    
                // ------------------------
                res = resposta[2];
                list=[];
                var pessoa ={};
                for (var i = 0; i < res.length; i++) {    
                    pessoa = {                              
                        pessoa_id : res[i].pessoa_id,          
                        nome_social : res[i].nome_social,                  
                        };
                    list.push(pessoa);   
                    this.setState({list_pessoa: list});  
                    }                    
                }       
            

        }catch{
            this.props.MapaKseg.callToast("Veículo não encontrado!");
        }     
        finally {
            let veiculo = this.state.veiculo;

            if(veiculo !== null)
                this.setState({loading: false});     
            else{
                this.props.MapaKseg.callToastError("Veículo não encontrado!");
                this.fecharModal();
            }
        }

    };    
    //----------------------------------------------------------------
    getVeiculoGrupos = () =>{
        var instance = new GetVeiculoGrupos(null, this, this.props.MapaKseg
                    , this.state.veiculo.veiculo_id );
        instance.get();   
    };        
    //-------------------------------------------------------------
    setListGrupos =  (_list) => {  
        let array =[]
        let adicionar=true;
        let grupos = this.state.veiculo.grupos;
        let veiculo = this.state.veiculo;
        veiculo.grupos = _list;

        for (var i = 0; i < _list.length; i++) { 
            for (var j = 0; j < grupos.length; j++) { 
                if(grupos[j].grupo_id == _list[i].grupo_id){
                    adicionar=false;
                    break;
                }else adicionar=true;
            }

            if(adicionar==true){
                array.push({
                    id: _list[i].grupo_id
                    , descricao: _list[i].placa
                    , selecionada: false
                    , index_scroll: 0});
            }
        }        
        // this.state.GeralListModal.setList(array, 0);

        this.setState({grupos: grupos, veiculo: veiculo, editado: true});
    }; 
    //-------------------------------------------
    onSalvar = async() => {
        this.setState({salvando:true, changed:true});
        var instance = new PostVeiculoCad(null, this, this.props.MapaKseg);
        instance.post();

        try {
            // --validar--
            var veiculo = this.state.veiculo;
            if(veiculo.tipo_entrada_1_id===null) veiculo.tipo_entrada_1_id=1 // 1- Nao usar
            if(veiculo.tipo_entrada_2_id===null) veiculo.tipo_entrada_2_id=1 // 1- Nao usar
            if(veiculo.tipo_saida_1_id===null) veiculo.tipo_saida_1_id=1 // 1- Nao usar
            if(veiculo.tipo_saida_2_id===null) veiculo.tipo_saida_2_id=1 // 1- Nao usar
            //-----
            var jsonObject = JSON.stringify(veiculo);
            console.log("jsonObject:");
            console.log(jsonObject);
            let body = new FormData()
            body.append('json', jsonObject);
            body.append('token', localStorage.getItem('token'));

            const response = await api.post('/cadastro_api/postVeiculoInformacao', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
 
                console.log("resposta:");
                console.log(resposta);
                
            
                veiculo.veiculo_entrada_saida_id = resposta.veiculo_entrada_saida_id;
                veiculo.edit_chip=false;
                veiculo.edit_placa=false;
                veiculo.edit_pessoa=false;
                veiculo.edit_tipo_chip=false;
                veiculo.edit_protocolo_tcp_udp=false;
                veiculo.edit_zip=false;
                veiculo.edit_entrada_saida=false;
                veiculo.edit_motorista = false;
                this.setState({veiculo: veiculo, editado:false});
                this.setState({disable_chip:true, disable_placa:true, disable_pessoa:true, disable_tipo_chip:true, disable_protocolo_tcp_udp:true
                    ,disable_pacote_dados_mensais:true, disable_placa_detalhes:true, disable_renavam:true, disable_ano_fabricacao:true
                    ,disable_marca_modelo:true, disable_cor_predominante:true, disable_chassi:true});
                // console.log("this.state.veiculo:");
                // console.log(this.state.veiculo);
                this.props.MapaKseg.callToast(resposta.mensagem);
                }       
            

        } finally {
            this.setState({loading: false});     
            }
    };
    //--------------------------------------
    concluido=()=> {
        this.getVeiculoGrupos();
        // this.getFilialUsuarios();
        this.setState({salvando:false, changed:false });     
        // this.state.FilialGrid.setFilial(this.state.filial);  
    }    
    //-------------------------
    onChangeSelect(event) {
        // console.log("event.target:");
        // console.log(event.target);
        // this.props.MapaKseg.callToast(event.target.id);
        var veiculo = this.state.veiculo;
        veiculo.edit_entrada_saida = true;
        if(event.target.id==="Entrada1")
            veiculo.tipo_entrada_1_id = event.target.value;
        else if(event.target.id==="Entrada2")
            veiculo.tipo_entrada_2_id = event.target.value;
        else if(event.target.id==="Saida1")
            veiculo.tipo_saida_1_id = event.target.value;
        else if(event.target.id==="Saida2")
            veiculo.tipo_saida_2_id = event.target.value;      
        else if(event.target.id==="formPessoa")
            veiculo.pessoa_id = event.target.value;  
        else if(event.target.id==="formTipoChip")
            veiculo.tipo_chip_id = event.target.value;  
        else if(event.target.id==="formProtocoloTcpUdp")
            veiculo.tcp = event.target.value;

        
        this.setState({veiculo: veiculo, editado: true});  
      }
    //-------------------------
    onEditCampo(event) {
        // this.props.MapaKseg.callToast(event.target.id);
        var veiculo = this.state.veiculo;
        if(event.target.id==="formChip"){
            this.setState({disable_chip: false}); 
            veiculo.edit_chip=true;
        }else if(event.target.id==="formPlaca"){
            this.setState({disable_placa: false}); 
            veiculo.edit_placa=true;
        }else if(event.target.id==="formPessoa"){
            this.setState({disable_pessoa: false}); 
            veiculo.edit_pessoa=true;
        }else if(event.target.id==="formTipoChip"){
            this.setState({disable_tipo_chip: false}); 
            veiculo.edit_tipo_chip=true;            
        }else if(event.target.id==="formProtocoloTcpUdp"){
            this.setState({disable_protocolo_tcp_udp: false}); 
            veiculo.edit_protocolo_tcp_udp=true;
        }else if(event.target.id==="formPacoteDadosMensais"){
            this.setState({disable_pacote_dados_mensais: false}); 
            veiculo.edit_pacote_dados_mensais=true;            
        }else if(event.target.id==="formPlacaDetalhes"){
            this.setState({disable_placa_detalhes: false}); 
            veiculo.edit_placa_detalhes=true;            
        }else if(event.target.id==="formRenavam"){
            this.setState({disable_renavam: false}); 
            veiculo.edit_renavam=true;            
        }else if(event.target.id==="formAnoFabricacao"){
            this.setState({disable_ano_fabricacao: false}); 
            veiculo.edit_ano_fabricacao=true;            
        }else if(event.target.id==="formMarcaModelo"){
            this.setState({disable_marca_modelo: false}); 
            veiculo.edit_marca_modelo=true;            
        }else if(event.target.id==="formCorPredominante"){
            this.setState({disable_cor_predominante: false}); 
            veiculo.edit_cor_predominante=true;            
        }else if(event.target.id==="formChassi"){
            this.setState({disable_chassi: false}); 
            veiculo.edit_chassi=true;            
        };

        this.setState({veiculo: veiculo, editado: true});
    }      
    //-------------------------
    updateInputValue(event) {
        var veiculo = this.state.veiculo;

        if(event.target.id==="formChip")
            veiculo.chip = event.target.value;
        else if(event.target.id==="formPlaca")
            veiculo.placa = event.target.value;    
        else if(event.target.id==="formPessoa")
            veiculo.pessoa_id = event.target.value;               

        this.setState({veiculo: veiculo});
        // this.props.MapaKseg.callToast(event.target.id);
     }
    //---------------------------------------------
    setZip= (e) => {
        let veiculo = this.state.veiculo;    
        veiculo.zip= !veiculo.zip;
        veiculo.edit_zip=true;
        this.setState({veiculo: veiculo, editado: true});
    }
     //----------------------------------
    //  handleSelect = (index) => {
    //     alert(index);
    //     // this.setState({selectedIndex: index}, () => {
    //     //     console.log('Selected tab: ' + this.state.selectedIndex);
    //     // })
    // }   
    //-----------------------------------------------------
    showGeralList= (e) => {   
        if(e.target.getAttribute('value') == MOTORISTA)
            this.props.Modals.showGeralList(this,"Motoristas", MOTORISTA);     
    };      
    //-----------------------------------------------------
    getList= (geralListModal, tipo) => { 
        this.setState({GeralListModal: geralListModal});  

        if(tipo == MOTORISTA){             
            var instance = new GetMotoristas(null, this, this.props.MapaKseg, MOTORISTA);
            instance.get(); 
        }
    };     
    //--------------------------------------------------------------
    setListMotoristas = (_list, tipo) => {  
        let array =[]
        for (var i = 0; i < _list.length; i++) { 
            array.push({id: _list[i].motorista_id
                , descricao: _list[i].nome + " ("+_list[i].pessoa_id+" - "+_list[i].nome_fantasia+")"
                , pessoa_id: _list[i].pessoa_id
                , selecionada: _list[i].selecionada
                , index_scroll: _list[i].index_scroll});
        }
        this.state.GeralListModal.setList(array, tipo);
    };  
    //-----------------------------------------------------------------------------
    setObjeto= (objeto, tipo) => { 
       if(tipo == MOTORISTA){
            let veiculo = this.state.veiculo;
            veiculo.motorista_id = objeto.id;
            veiculo.motorista = objeto.descricao;
            veiculo.edit_motorista=true;

            this.setState({veiculo: veiculo});
        }

        this.setState({editado: true});
    }    
    //-------------------------
    render(){
        const handleSelect = (eventKey) => { 
            // alert(`selected ${eventKey}`);
            if (eventKey == "#Grupos"){
                if (this.state.gruposLoaded == false){
                    this.getVeiculoGrupos();
                    this.setState({gruposLoaded: true});
                }
            }
        };
        //--------------------
        return(
            <div className="drag1">
                <Draggable  handle="strong"  >                
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={700}
                            height={600}
                            onAnimationEnd={()=>this.desativarModal()}
                            >

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                {this.state.loading ?
                                        <Spinner className="centro3" animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    :
                                        <div style={{height:'600px', overflow:'hidden'}}>
                                            <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0">
                                                {/*------------------------------------ */}
                                                <Form >   
                                                    
                                                    <Form.Group as={Row} controlId="formPlaca">
                                                        <Form.Label column sm={2}>
                                                            Veículo
                                                        </Form.Label>
                                                        <Col sm={6}>
                                                            <InputGroup >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formPlaca" variant="outline-secondary" onClick={this.onEditCampo.bind(this)}><Image id="formPlaca" src={require('./img/pencil_48.png')} width='20'/></Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>{this.state.veiculo.veiculo_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.veiculo.placa} disabled={this.state.disable_placa} onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>     

                                                    {/* <Form.Group as={Row} controlId="formRastreador">
                                                        <Form.Label column sm={3}>
                                                            Rastreador
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >        
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>{this.state.veiculo.veiculo_externo}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.veiculo.rastreador} disabled/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>                       */}

                                                    <Form.Group as={Row} controlId="formPessoa">
                                                        <Form.Label column sm={2}>
                                                            Pessoa
                                                        </Form.Label>
                                                        <Col sm={6}>
                                                            <InputGroup  >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formPessoa" variant="outline-secondary" onClick={this.onEditCampo.bind(this)}><Image id="formPessoa" src={require('./img/pencil_48.png')} width='20'/></Button>
                                                                </InputGroup.Prepend>

                                                                <Form.Control as="select" aria-describedby="basic-addon1" disabled={this.state.disable_pessoa} defaultValue="..." custom onChange={this.onChangeSelect.bind(this)}>
                                                                                <option value={this.state.veiculo.pessoa_id}>
                                                                                    {this.state.veiculo.nome_social}
                                                                                </option>

                                                                                {this.state.list_pessoa.map(pessoa =>
                                                                                    pessoa.pessoa_id !== this.state.veiculo.pessoa_id ?
                                                                                        <option value={pessoa.pessoa_id}>
                                                                                            {pessoa.nome_social}
                                                                                        </option>
                                                                                    :""
                                                                                )}

                                                                </Form.Control>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group> 

                                                    <Form.Group as={Row} controlId="formVeiculoMotorista">
                                                        <Form.Label column sm={2}>
                                                            Motorista
                                                        </Form.Label>
                                                        <Col sm={6}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formVeiculoMotoristaBt" value={MOTORISTA} onClick={this.showGeralList} variant="outline-secondary" >
                                                                        <Image value={MOTORISTA} src={require('./img/text_box_search48.png')} width='20'/>
                                                                    </Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>{this.state.veiculo.motorista_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control  aria-describedby="basic-addon1" value={this.state.veiculo.motorista}  disabled="true"> 

                                                                </Form.Control>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>                                                     

                                                    {/* ----------------Tab---------------------------------------------------- */}
                                                    <Card>
                                                        <Card.Header>
                                                            <Nav variant="tabs" defaultActiveKey="#Entradas" onSelect={handleSelect} >
                                                                <Nav.Item >
                                                                    <Nav.Link  data-toggle="tab" href="#Entradas">Entradas</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link data-toggle="tab" href="#Saidas">Saídas</Nav.Link>
                                                                </Nav.Item>   
                                                                <Nav.Item>
                                                                    <Nav.Link data-toggle="tab" href="#Grupos" >Grupos</Nav.Link>
                                                                </Nav.Item>    
                                                                {/* <Nav.Item>
                                                                    <Nav.Link data-toggle="tab" href="#Detalhes">Detalhes</Nav.Link>
                                                                </Nav.Item>   */}
                                                                <Nav.Item>
                                                                    <Nav.Link data-toggle="tab" href="#Rastreador">Rastreador</Nav.Link>
                                                                </Nav.Item>      
                                                                {/* <Nav.Item>
                                                                    <Nav.Link data-toggle="tab" href="#Anexos">Anexos</Nav.Link>
                                                                </Nav.Item>                                               */}
                                                            </Nav>
                                                        </Card.Header>

                                                        <div class="tab-content">
                                                            <Card.Body id="Entradas" className="tab-pane active">
                                                                <Form.Group as={Row}   controlId="Entrada1">
                                                                        <Form.Label>
                                                                            Entrada 1
                                                                        </Form.Label>
                                                                        <Col sm={4}>
                                                                            <Form.Control as="select" defaultValue="..." custom onChange={this.onChangeSelect.bind(this)}>
                                                                                <option value={this.state.veiculo.tipo_entrada_1_id}>
                                                                                    {this.state.veiculo.entrada1}
                                                                                </option>

                                                                                {this.state.list.map(tipo_entrada_saida =>
                                                                                    tipo_entrada_saida.tipo_entrada_saida_id !== this.state.veiculo.tipo_entrada_1_id 
                                                                                            &&  tipo_entrada_saida.entrada===true ?
                                                                                        <option value={tipo_entrada_saida.tipo_entrada_saida_id}>
                                                                                            {tipo_entrada_saida.descricao}
                                                                                        </option>
                                                                                    :""
                                                                                )}

                                                                            </Form.Control>
                                                                        </Col>
                                                                    </Form.Group> 
                                                                    {/* ------------ */}
                                                                    <Form.Group as={Row}   controlId="Entrada2">
                                                                        <Form.Label>
                                                                            Entrada 2
                                                                        </Form.Label>
                                                                        <Col sm={4}>
                                                                            <Form.Control as="select" defaultValue="..." custom onChange={this.onChangeSelect.bind(this)}>
                                                                                <option  value={this.state.veiculo.tipo_entrada_2_id}>
                                                                                    {this.state.veiculo.entrada2}
                                                                                </option>

                                                                                {this.state.list.map(tipo_entrada_saida =>
                                                                                    tipo_entrada_saida.tipo_entrada_saida_id !== this.state.veiculo.tipo_entrada_2_id 
                                                                                            &&  tipo_entrada_saida.entrada===true ?
                                                                                        <option  value={tipo_entrada_saida.tipo_entrada_saida_id}>
                                                                                            {tipo_entrada_saida.descricao}
                                                                                        </option>
                                                                                    :""
                                                                                )}
                                                                            </Form.Control>
                                                                        </Col>
                                                                </Form.Group> 
                                                            </Card.Body>

                                                            {/* ------------------------------------ */}
                                                            
                                                            <Card.Body id="Saidas" className="tab-pane fade">
                                                                    <Form.Group as={Row}   controlId="Saida1">
                                                                        <Form.Label>
                                                                            Saída 1
                                                                        </Form.Label>
                                                                        <Col sm={4}>
                                                                            <Form.Control as="select" defaultValue="..." custom onChange={this.onChangeSelect.bind(this)}>
                                                                                <option value={this.state.veiculo.tipo_saida_1_id}>
                                                                                    {this.state.veiculo.saida1}
                                                                                </option>

                                                                                {this.state.list.map(tipo_entrada_saida =>
                                                                                    tipo_entrada_saida.tipo_entrada_saida_id !== this.state.veiculo.tipo_saida_1_id ?                                                         
                                                                                        tipo_entrada_saida.entrada===false || tipo_entrada_saida.tipo_entrada_saida_id===1? // 1 - Nao usar
                                                                                            <option value={tipo_entrada_saida.tipo_entrada_saida_id}>
                                                                                                {tipo_entrada_saida.descricao}
                                                                                            </option>
                                                                                        :""
                                                                                    :""
                                                                                )}
                                                                            </Form.Control>
                                                                        </Col>
                                                                    </Form.Group> 
                                                                    {/* ------------ */}
                                                                    <Form.Group as={Row}   controlId="Saida2">
                                                                        <Form.Label>
                                                                        Saída 2
                                                                        </Form.Label>
                                                                        <Col sm={4}>
                                                                            <Form.Control as="select" defaultValue="..." custom onChange={this.onChangeSelect.bind(this)}>
                                                                                <option value={this.state.veiculo.tipo_saida_2_id}>
                                                                                    {this.state.veiculo.saida2}
                                                                                </option>

                                                                                {this.state.list.map(tipo_entrada_saida =>
                                                                                    tipo_entrada_saida.tipo_entrada_saida_id !== this.state.veiculo.tipo_saida_2_id ?
                                                                                        tipo_entrada_saida.entrada===false || tipo_entrada_saida.tipo_entrada_saida_id===1? // 1 - Nao usar
                                                                                            <option value={tipo_entrada_saida.tipo_entrada_saida_id}>
                                                                                                {tipo_entrada_saida.descricao}
                                                                                            </option>
                                                                                        :""
                                                                                    :""
                                                                                )}
                                                                            </Form.Control>
                                                                        </Col>
                                                                    </Form.Group> 
                                                            </Card.Body>     
                                                            {/* ------------------------------------ */}
                                                            
                                                            <Card.Body id="Grupos" className="tab-pane fade">
                                                                {/* {this.state.grupos.length > 0 ? */}
                                                                {this.state.gruposLoaded ?
                                                                <VeiculoGruposGrid  
                                                                        objeto={this}
                                                                        height_grid={this.state.height_grid} 
                                                                        MapaKseg={this.props.MapaKseg} 
                                                                        Modals={this.props.Modals}/>                 
                                                                : <Spinner className="centro3" animation="border" role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                    </Spinner>
                                                                    }
                                                            </Card.Body>                                                              
                                                            {/* ------------------------------------ */}
                                                            
                                                            <Card.Body id="Rastreador" className="tab-pane fade">
                                                                <Form.Group as={Row} controlId="formRastreador">
                                                                    <Form.Label column sm={2}>
                                                                        Rastreador
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-3" >        
                                                                            <InputGroup.Prepend>
                                                                                <InputGroup.Text>{this.state.veiculo.veiculo_externo}</InputGroup.Text>
                                                                            </InputGroup.Prepend>
                                                                            <FormControl aria-describedby="basic-addon1" value={this.state.veiculo.rastreador} disabled/>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 
                                                                
                                                                <Form.Group as={Row} controlId="formChip">
                                                                    <Form.Label column sm={2}>
                                                                        N° Chip
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-3" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formChip" variant="outline-secondary" onClick={this.onEditCampo.bind(this)}><Image id="formChip" src={require('./img/pencil_48.png')} width='20'/></Button>
                                                                            </InputGroup.Prepend>
                                                                            <FormControl aria-describedby="basic-addon1" value={this.state.veiculo.chip} disabled={this.state.disable_chip} onChange={this.updateInputValue.bind(this)}/>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formTipoChip">
                                                                    <Form.Label column sm={2}>
                                                                        Chip
                                                                    </Form.Label>
                                                                    <Col sm={3}>
                                                                        <InputGroup className="mb-3" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formTipoChip" variant="outline-secondary" onClick={this.onEditCampo.bind(this)}><Image id="formTipoChip" src={require('./img/pencil_48.png')} width='20'/></Button>
                                                                            </InputGroup.Prepend>

                                                                            <Form.Control as="select" aria-describedby="basic-addon1" disabled={this.state.disable_tipo_chip} defaultValue="..." custom onChange={this.onChangeSelect.bind(this)}>
                                                                                <option value={this.state.veiculo.tipo_chip_id}>
                                                                                    {this.state.veiculo.tipo_chip}
                                                                                </option>

                                                                                {this.state.list_tipo_chip.map(tipo_chip =>
                                                                                    tipo_chip.tipo_chip_id !== this.state.veiculo.tipo_chip_id ?
                                                                                        <option value={tipo_chip.tipo_chip_id}>
                                                                                            {tipo_chip.descricao}
                                                                                        </option>
                                                                                    :""
                                                                                )}

                                                                            </Form.Control>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formProtocoloTcpUdp">
                                                                    <Form.Label column sm={2}>
                                                                        Protocolo 
                                                                    </Form.Label>
                                                                    <Col sm={7}>
                                                                        <InputGroup className="mb-3" >
                                                            
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formProtocoloTcpUdp" variant="outline-secondary" onClick={this.onEditCampo.bind(this)}><Image id="formProtocoloTcpUdp" src={require('./img/pencil_48.png')} width='20'/></Button>
                                                                            </InputGroup.Prepend>

                                                                            <Form.Control as="select" aria-describedby="basic-addon1" disabled={this.state.disable_protocolo_tcp_udp} defaultValue="..." custom onChange={this.onChangeSelect.bind(this)}>
                                                                                <option value={this.state.veiculo.tcp}>
                                                                                    {this.state.veiculo.protocolo_tcp_udp}
                                                                                </option>

                                                                                {this.state.list_protocolo_tcp_udp.map(protocolo_tcp_udp =>
                                                                                    protocolo_tcp_udp.valor !== this.state.veiculo.tcp ?
                                                                                        <option value={protocolo_tcp_udp.valor}>
                                                                                            {protocolo_tcp_udp.descricao}
                                                                                        </option>
                                                                                    :""
                                                                                )}

                                                                            </Form.Control>

                                                                            <Form.Label column sm={3} className="padding_left" > Zip </Form.Label>
                                                                            <Switch  className="padding_top" defaultValue={this.state.veiculo.zip} value={this.state.veiculo.zip}  switchedOnText={"Sim"} switchedOffText={"Não"}  onValueChanged={this.setZip} />

                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                {/* <Form.Group as={Row} controlId="formPacoteDadosMensais">
                                                                    <Form.Label column sm={4}>
                                                                    Pacote de dados Mensais
                                                                    </Form.Label>
                                                                    <Col sm={4}>
                                                                        <InputGroup className="mb-3" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formPacoteDadosMensais" variant="outline-secondary" onClick={this.onEditCampo.bind(this)}><Image id="formPacoteDadosMensais" src={require('./img/pencil_48.png')} width='20'/></Button>
                                                                            </InputGroup.Prepend>
                                                                            <FormControl aria-describedby="basic-addon1" value={this.state.veiculo.pacote_dados_mensais} disabled={this.state.disable_pacote_dados_mensais} onChange={this.updateInputValue.bind(this)}/>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group>  */}

                                                            </Card.Body>
                                                            {/* ------------------------------------ */}
                                                            
                                                            {/* <Card.Body id="Detalhes" className="tab-pane fade">
                                                            <Form.Group as={Row} controlId="formPlacaDetalhes">
                                                                    <Form.Label column sm={3}>
                                                                        Placa
                                                                    </Form.Label>
                                                                    <Col sm={5}>
                                                                        <InputGroup className="mb-3" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formPlacaDetalhes" variant="outline-secondary" onClick={this.onEditCampo.bind(this)}><Image id="formPlacaDetalhes" src={require('./img/pencil_48.png')} width='20'/></Button>
                                                                            </InputGroup.Prepend>
                                                                            <FormControl aria-describedby="basic-addon1" value={this.state.veiculo.placa_detalhes} disabled={this.state.disable_placa_detalhes} onChange={this.updateInputValue.bind(this)}/>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formRenavam">
                                                                    <Form.Label column sm={3}>
                                                                        Renavam
                                                                    </Form.Label>
                                                                    <Col sm={5}>
                                                                        <InputGroup className="mb-3" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formRenavam" variant="outline-secondary" onClick={this.onEditCampo.bind(this)}><Image id="formRenavam" src={require('./img/pencil_48.png')} width='20'/></Button>
                                                                            </InputGroup.Prepend>
                                                                            <FormControl aria-describedby="basic-addon1" value={this.state.veiculo.renavam} disabled={this.state.disable_renavam} onChange={this.updateInputValue.bind(this)}/>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formAnoFabricacao">
                                                                    <Form.Label column sm={3}>
                                                                        Ano Fabricação
                                                                    </Form.Label>
                                                                    <Col sm={4}>
                                                                        <InputGroup className="mb-3" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formAnoFabricacao" variant="outline-secondary" onClick={this.onEditCampo.bind(this)}><Image id="formAnoFabricacao" src={require('./img/pencil_48.png')} width='20'/></Button>
                                                                            </InputGroup.Prepend>
                                                                            <FormControl aria-describedby="basic-addon1" value={this.state.veiculo.ano_fabricacao} disabled={this.state.disable_ano_fabricacao} onChange={this.updateInputValue.bind(this)}/>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group>

                                                                <Form.Group as={Row} controlId="formMarcaModelo">
                                                                    <Form.Label column sm={3}>
                                                                    Marca/Modelo
                                                                    </Form.Label>
                                                                    <Col sm={4}>
                                                                        <InputGroup className="mb-3" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formMarcaModelo" variant="outline-secondary" onClick={this.onEditCampo.bind(this)}><Image id="formMarcaModelo" src={require('./img/pencil_48.png')} width='20'/></Button>
                                                                            </InputGroup.Prepend>
                                                                            <FormControl aria-describedby="basic-addon1" value={this.state.veiculo.marca_modelo} disabled={this.state.disable_marca_modelo} onChange={this.updateInputValue.bind(this)}/>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formCorPredominante">
                                                                    <Form.Label column sm={3}>
                                                                    Cor Predominante
                                                                    </Form.Label>
                                                                    <Col sm={4}>
                                                                        <InputGroup className="mb-3" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formCorPredominante" variant="outline-secondary" onClick={this.onEditCampo.bind(this)}><Image id="formCorPredominante" src={require('./img/pencil_48.png')} width='20'/></Button>
                                                                            </InputGroup.Prepend>
                                                                            <FormControl aria-describedby="basic-addon1" value={this.state.veiculo.cor_predominante} disabled={this.state.disable_cor_predominante} onChange={this.updateInputValue.bind(this)}/>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formChassi">
                                                                    <Form.Label column sm={3}>
                                                                    Chassi
                                                                    </Form.Label>
                                                                    <Col sm={4}>
                                                                        <InputGroup className="mb-3" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formChassi" variant="outline-secondary" onClick={this.onEditCampo.bind(this)}><Image id="formChassi" src={require('./img/pencil_48.png')} width='20'/></Button>
                                                                            </InputGroup.Prepend>
                                                                            <FormControl aria-describedby="basic-addon1" value={this.state.veiculo.chassi} disabled={this.state.disable_chassi} onChange={this.updateInputValue.bind(this)}/>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                            </Card.Body>    */}
                                                            {/* ------------------------------------ */}
                                                                                                                        
                                                            {/* <Card.Body id="Anexos" className="tab-pane fade"> */}
                                                                {/* <Form.Group as={Row}   controlId="Anexos1">
                                                                    <Form.Label>
                                                                    Anexos 1
                                                                    </Form.Label>
                                                                    <Col sm={5}>
                                                                        <Form.Control as="select" defaultValue="..." custom onChange={this.onChangeSelect.bind(this)}>
                                                                            <option value={this.state.veiculo.tipo_saida_1_id}>
                                                                                {this.state.veiculo.saida1}
                                                                            </option>

                                                                            {this.state.list.map(tipo_entrada_saida =>
                                                                                tipo_entrada_saida.tipo_entrada_saida_id !== this.state.veiculo.tipo_saida_1_id ?                                                         
                                                                                    tipo_entrada_saida.entrada===false || tipo_entrada_saida.tipo_entrada_saida_id===1? // 1 - Nao usar
                                                                                        <option value={tipo_entrada_saida.tipo_entrada_saida_id}>
                                                                                            {tipo_entrada_saida.descricao}
                                                                                        </option>
                                                                                    :""
                                                                                :""
                                                                            )}
                                                                        </Form.Control>
                                                                    </Col>
                                                                </Form.Group>  */}
                                                                {/* ------------ */}
                                                                {/* <Form.Group as={Row}   controlId="Anexos2">
                                                                    <Form.Label>
                                                                    Anexos 2
                                                                    </Form.Label>
                                                                    <Col sm={5}>
                                                                        <Form.Control as="select" defaultValue="..." custom onChange={this.onChangeSelect.bind(this)}>
                                                                            <option value={this.state.veiculo.tipo_saida_2_id}>
                                                                                {this.state.veiculo.saida2}
                                                                            </option>

                                                                            {this.state.list.map(tipo_entrada_saida =>
                                                                                tipo_entrada_saida.tipo_entrada_saida_id !== this.state.veiculo.tipo_saida_2_id ?
                                                                                    tipo_entrada_saida.entrada===false || tipo_entrada_saida.tipo_entrada_saida_id===1? // 1 - Nao usar
                                                                                        <option value={tipo_entrada_saida.tipo_entrada_saida_id}>
                                                                                            {tipo_entrada_saida.descricao}
                                                                                        </option>
                                                                                    :""
                                                                                :""
                                                                            )}
                                                                        </Form.Control>
                                                                    </Col>
                                                                </Form.Group>  */}
                                                            {/* </Card.Body> */}


                                                        </div>
                                                    </Card>  
                                                                                                                                                   

                                                </Form>
                                                {/* ----------------------------------- */}
                                            </CustomScroll>


                                            <Modal.Footer >
                                                        {/* <div className="align_bottom_left">
                                                            <Button onClick={this.onVoltarClick}><img src={require('./img/arrow_left24.png')} title={"Voltar"}/></Button>
                                                        </div> */}
                                                        {this.state.editado?
                                                            <div className="align_bottom_right">
                                                                <Button onClick={this.onSalvar}><img src={require('./img/checkbold24.png')}/>Salvar</Button>
                                                            </div> 
                                                        :
                                                            <div className="align_bottom_right">
                                                                <Button disabled><img src={require('./img/checkbold24.png')}/>Salvar</Button>
                                                            </div>    
                                                        }
                                            </Modal.Footer>

                                        </div>
                                    }
                                </div>
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>        
        );
    }
}