import React, {Component} from 'react';
import {Modal, Form, Button as Button_bootstrap, Row, Col, Spinner} from 'react-bootstrap';
import '../estilo.css';
import {CabecalhoModal} from '../CabecalhoModal';
import { TextArea } from 'devextreme-react/text-area';
import api from '../api';


export  class CompartilharRastreamentoModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bottomSheet:true, 
            showSheet:true,
            tempo_compartilhado: "24Horas",
            link_rastreamento: "",
            disable_botao_gerar_link: false,
            gerando_link:false,
        };
      }   
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeCompartilharRastreamentoModal();
    };
    //------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarCompartilharRastreamentoModal();
    };
    //--------------------------
    async componentDidMount() {
        this.setState({texto: this.props.texto, tipo: this.props.tipo});
    };
    //-----------------
    sleep (milliseconds) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    }
    //--------------------
    gerandoLink = async() =>{
        await this.sleep(1000);
        this.gerarLinkRastreio();
    }
    //------------------------------------------------------------------
    formatarMes(mes){    
    if(mes==="Jan")
        return 1;
    else if (mes==="Feb")
        return 2;
    else if (mes==="Mar")
        return 3;     
    else if (mes==="Apr")
        return 4;     
    else if (mes==="May")
        return 5;     
    else if (mes==="Jun")
        return 6; 
    else if (mes==="Jul")
        return 7; 
    else if (mes==="Aug")
        return 8; 
    else if (mes==="Sep")
        return 9; 
    else if (mes==="Oct")
        return 10;    
    else if (mes==="Nov")
        return 11;
    else if (mes==="Dec")
        return 12;             
    }
    //-------------------------
    formatarDateTime(dtTime){    
        const y = String(dtTime).substring(11,15);
        const mm = this.formatarMes(String(dtTime).substring(4,7));
        const dd = String(dtTime).substring(8,10);
        const hm = String(dtTime).substring(16,21);

        // console.log("  *** dtTime: "+String(dtTime));
        // console.log("  *** y: "+y);
        // console.log("  *** mm: "+mm);
        // console.log("  *** mm: "+String(this.formatarMes(mm)));
        // console.log("  *** dd: "+dd);
        // console.log("  *** hm: "+hm);  
        const datetime = y+"-"+mm+"-"+dd+" "+hm;  // "Y-MM-DD H:M"
        return datetime;                  
      }
    //-------------------------
    gerarLinkRastreio = async () => {
        var grupo_temporario_id="0";
        try {
            var dias=1;
            if(this.state.tempo_compartilhado == "24Horas") dias = 1;
            else if(this.state.tempo_compartilhado == "48Horas") dias = 2;
            else if(this.state.tempo_compartilhado == "1Semana") dias = 7;
            else if(this.state.tempo_compartilhado =="2Semanas") dias = 14;        

            var data_f = new Date();    
            data_f.setDate(data_f.getDate() + dias);
            var data_final = this.formatarDateTime(String(data_f))

            let body = new FormData()
            body.append('pessoa_id', this.props.MapaKseg.state.pessoa_id);
            body.append('data_final', String(data_final)+":00");
            body.append('usuario_id', this.props.MapaKseg.state.usuario_id);
            body.append('veiculo_id', this.props.obj_selected.veiculo_id);
            body.append('token', localStorage.getItem('token'));

            const response = await api.post('/jornada_motorista_api/insertGrupoTemporario', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
            
                var resposta = JSON.parse(req.json);                  
                console.log("resposta:");
                console.log(resposta);
                grupo_temporario_id = String(resposta.grupo_temporario_id);
                                 
            }       
            

        } finally {
            this.setState({link_rastreamento:"https://ksegweb.com.br/MapaRastreio/"+grupo_temporario_id+'/'+String(localStorage.getItem('token'))});
            this.setState({gerando_link:false});    
            }
    };    
    //------------------------
    okClick = ()=>{
        // this.props.objeto.confirmarObservacao(this.state.texto);
        // this.fecharModal();
        // this.props.MapaKseg.callToast(this.state.tempo_compartilhado);
        this.setState({disable_botao_gerar_link:true, gerando_link:true});          
        this.setState({link_rastreamento: "http://localhost:3000/MapaRastreio/1/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoibWFybG9ubmdhbHZhb0BnbWFpbC5jb20iLCJleHAiOjE3NDU1MjMyMTV9.ICU8Jpsbceql2JYcWK24qsdmCNgdEEw_bxHj1pjxJnc"});
        this.gerandoLink();
    };

    //-------------------------  <img src={this.props.comando.icone} /> {this.props.comando.descricao}
    render(){
        return(
            <div className="splashscreen-modal1">
                <Modal  centered
                    {...this.props}
                    
                    aria-labelledby="example-custom-modal-styling-title"
                    scrollable="True"
                    dialogClassName="temperatura-modal2"    
                    >
                    <div >
                        <strong className="cursor">
                            <CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.titulo} Modal={this}/>
                            {/* <div className="cabecalho"> 
                                <label className="titulo2" > {this.props.titulo} </label>
                            </div> */}
                        </strong>
                            
                        <Modal.Body>           
                            <Form>
                                <h6>
                                    Para compartilhar um Link de Rastreamento do veículo {this.props.obj_selected.placa}, informe o tempo que ficará disponível para visualização.
                                </h6>                                
                            
                                <div className="mb-3">
                                    <Form.Check         
                                        defaultChecked                               
                                        label="24 Horas"
                                        type={'radio'}
                                        id={"24Horas"}
                                        name="group1"
                                        onClick={ (e) => { this.setState({tempo_compartilhado:"24Horas"}) } }
                                    />
                                    <Form.Check                                        
                                        label="48 Horas"
                                        type={'radio'}
                                        id={"48Horas"}
                                        name="group1"
                                        onClick={ (e) => { this.setState({tempo_compartilhado:"48Horas"}) } }
                                    />
                                    <Form.Check                                        
                                        label="1 Semana"
                                        type={'radio'}
                                        id={"1Semana"}
                                        name="group1"
                                        onClick={ (e) => { this.setState({tempo_compartilhado:"1Semana"}) } }
                                    />
                                    <Form.Check                                        
                                        label="2 Semanas"
                                        type={'radio'}
                                        id={"2Semanas"}
                                        name="group1"
                                        onClick={ (e) => { this.setState({tempo_compartilhado:"2Semanas"}) } }
                                    />
                                </div>

                                {this.state.gerando_link
                                    ? <Spinner as="span" animation="grow" role="status" aria-hidden="true"/>                                                                
                                    :this.state.link_rastreamento.length > 0 ?
                                        <div >
                                            <TextArea maxLength={50} defaultValue={this.state.link_rastreamento}/>

                                            <Button_bootstrap variant="secondary" size="sm"                                         
                                            onClick={() => {navigator.clipboard.writeText(this.state.link_rastreamento);
                                                this.props.MapaKseg.callToast("Copiado!");
                                            }}>
                                                Copiar</Button_bootstrap> 
                                        </div>
                                    : ""}
                            </Form>
                        </Modal.Body>
                        
                    </div>


                    <Modal.Footer>   
                        <Button_bootstrap onClick={this.okClick}
                            disabled={this.state.disable_botao_gerar_link}>Gerar Link</Button_bootstrap>                
                    </Modal.Footer>

                </Modal>

            </div>
        );
    }
}