import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import "./styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: 'flex',
    marginTop: 5,
    marginBottom: 5,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  details_gray: {
    background: "#bdbebd",
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    margin: 8,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

//--------------------------------  
var moment = require('moment');
require('moment-precise-range-plugin');
//------------------------------Expandir Texto----------------------

const styles = {
  container: {
    width: 800,
    margin: "0 auto"
  },
  card: {
    backgroundColor: "#B7E0F2",
    borderRadius: 55,
    padding: "3rem"
  }
};

const MAX_POSSIBLE_HEIGHT = 800;

const ExpendableBody = ({ maxHeight, children, titulo_anotacao, usuario_anotacao_id, props }) => {
  const ref = useRef();
  const [shouldShowExpand, setShouldShowExpand] = useState(false);
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    if (ref.current.scrollHeight > maxHeight) {
      setShouldShowExpand(true);
      setExpanded(false);
    }
  }, [maxHeight]);

  return (
    <Card.Body>
      <Card.Title>        
        <button 
        style={{backgroundColor: 'transparent', border: 0, outline: 'none'}}
        onClick={() => setExpanded(!expanded)} >{titulo_anotacao}</button>
      </Card.Title>

      <Card.Text style={styles.cardText} ref={ref}>
        <div
          class="inner"
          style={{ maxHeight: expanded ? MAX_POSSIBLE_HEIGHT : maxHeight }}
        >
          {children}
          <br />
          <Button variant="outline-dark" onClick={
            () => editarAnotacao(props)}>Editar</Button>

          <Button variant="outline-danger" onClick={
            () => deleteAnotacao(props)}>Remover</Button>
        </div>
      </Card.Text>
    </Card.Body>
  );

  //----------------------------------------
  function deleteAnotacao (props){
    setExpanded(!expanded);
    props.UsuarioAnotacaoModal.deleteAnotacao(props.value);
  }; 
  //----------------------------------------
  function editarAnotacao (props){
    props.UsuarioAnotacaoModal.editarAnotacao(props.value);
  }; 
};
//---------------------
function cardAnotacao (props){
  const classes = useStyles();
  const { value, MapaKseg, showMapIcon } = props;


  return(
    <>
      <Card 
        // bg={value.color}
        // border={value.color}
        style={{ width: '18rem', backgroundColor: value.color }}
        // text={value.color === 'light' ? 'dark' : 'white'}
        // className="mb-2"
        >

        <Card.Header>{value.data_anotacao}</Card.Header>
        {/* <Card.Header>{String(value.color).toLowerCase()} </Card.Header> */}
          <ExpendableBody maxHeight={1} titulo_anotacao={value.titulo_anotacao} usuario_anotacao_id={value.usuario_anotacao_id} props = {props}>
            {value.anotacao}
          </ExpendableBody>          
      </Card>
      <br />
    </>
  );

  // return(
  //   <>
  //     <Card border="primary" style={{ width: '18rem' }}>
  //       <Card.Header>{value.data_anotacao}</Card.Header>
  //       {/* <Card.Header>{dia_mes+ " "+hora}</Card.Header> */}
  //       <Card.Body>
  //         <Card.Title>{value.titulo_anotacao}</Card.Title>
  //         <ExpendableText maxHeight={95}>
  //           {value.anotacao}
  //         </ExpendableText>
  //         <Button variant="outline-dark">Editar</Button>
  //         <Button variant="outline-danger">Remover</Button>
  //       </Card.Body>
  //     </Card>
  //     <br />
  //   </>
  // );

};
//--------------------
export default function AnotacaoCard(props) {  
  const {value} = props;
  return ( 
      cardAnotacao(props)
  );
}