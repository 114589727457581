import React, {Component} from 'react';
import {Modal, Image, Spinner, Button, Form, Row,Col,Card,Nav,InputGroup,FormControl} from 'react-bootstrap';
import '../estilo.css';
import {CabecalhoModal} from '../CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import GetPessoa from '../services/get_pessoa';
import GetCartoes from '../services/get_cartoes';
import PostMotoristaCad from '../services/post_motorista_cad';
import {MotoristaPessoasGrid} from '../datagrid/motorista_pessoas_grid';
import GetMotoristaPessoas from '../services/get_motorista_pessoas';
import {MotoristaCartoesGrid} from '../datagrid/motorista_cartoes_grid';
import GetMotoristaCartoes from '../services/get_motorista_cartoes';
import api from '../api';


const PESSOA_TIPO = 1;
const CARTAO_TIPO = 2;

export class MotoristaCadModal extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            animation: "zoom",
            list:[],
            load:true,
            minimizar:false,
            changed:false,
            veiculo:null,
            motorista:null,
            motoristaPessoaList:[],
            pessoas:[],

            GeralListModal:null,            

            mostrarSenha:false,
            type_senha: "password",

            height_modal:550,
            height_grid:0,
            items:[],
            editados: {},

            salvando:false,
            MotoristasGrid:null
        };

         // -------------------------------
        this.dataGridRef = React.createRef();
    
        this.selectedRowsData = [];

        this.getSelectedData = () => {
            this.selectedRowsData = this.dataGrid.getSelectedRowsData();
            this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            });
        }

        this.onValueChangedCheck = this.onValueChangedCheck.bind(this);

      }
    //---------------------------------------------------------------
    get dataGrid() {
        return this.dataGridRef.current.instance;
    }
    //-------------------------------------------------------------------
    async componentDidMount() {
        // INICIALIZAR VARIÁVEIS

        this.setState({MotoristasGrid: this.props.objeto});

        let editados = {
            nome:false     
            ,pessoas: false
            ,cartoes: false
             };

        let motorista = this.props.obj_selected;
        motorista.pessoas = [];
        motorista.cartoes = [];

        this.setState({
                motorista: motorista       
                ,editados: editados        
                , height_grid: this.state.height_modal-370},
            () => { 
                if(motorista.motorista_id != 0){
                    this.getMotoristaPessoas();
                    this.getMotoristaCartoes();
                }
                this.setState({load:false}) }
          )
    }; 
    //-------------------------------------------------------------------
    getMotoristaPessoas = () =>{
        var instance = new GetMotoristaPessoas(null, this, this.props.MapaKseg, this.state.motorista.motorista_id);
        instance.get();   
    };
    //-------------------------------------------------------------------
    getMotoristaCartoes = () =>{
        var instance = new GetMotoristaCartoes(null, this, this.props.MapaKseg, this.state.motorista.motorista_id);
        instance.get();   
    };
    //----------------------------------------------------------------
    // getMotoristaPessoa = () =>{
    //     let tipo_grupo_id = 1;
    //     var instance = new this.getMotoristaPessoa(null, this, this.props.MapaKseg
    //                 , this.state.motorista.motorista_id
    //                 ,tipo_grupo_id );
    //     instance.get();   
    // }; 
    //------------------------------------------------------------------
    getMotoristaPessoa0 = async () => {
        try {
            let body = new FormData()
            body.append('motorista_id', this.state.motorista.motorista_id);
            body.append('token', localStorage.getItem('token'));

            const response = await api.post('/cadastro_api/getMotoristaIDPessoas', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let motorista={};
                let motoristaPessoaList = this.state.motoristaPessoaList;                

                // ToastStore.success("resposta.length: "+ String(resposta.length));
                for (var i = 0; i < resposta.length; i++) {                        
                    motorista = {    
                        pessoa_id: resposta[i].pessoa_id,
                        nome_fantasia: resposta[i].nome_fantasia,
                        selecionada: false,
                        index_scroll: (i+2)*25,
                    };

                    motoristaPessoaList.push(motorista);
                    this.setState({motoristaPessoaList: motoristaPessoaList});
                }

                this.setState({loading: false});
                
            }
        } finally {      
            }

    }; 
    //--------------------------------------------------------------------
    setList = (_list) => {
        let motorista = this.state.motorista;
        let _motorista = _list[0];

        motorista.motorista_id = _motorista.motorista_id;
        motorista.nome = _motorista.nome;

        this.setState({motorista: motorista});
    }  
    //------------------------------------------------------------------------
    minimizarModal = () =>{
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        this.props.Modals.closeMotoristaCadModal();  
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeMotoristaCadModal();
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarMotoristaCadModal();
    };
    //-------------------------------------------------------------
    ativarEditCampo(event) {       
         if(event.target.id==="formSenha"){
            this.setState({disable_senha: false, type_senha:"text" }); 
        }        
    }      
    //------------------------------------------------------
    updateInputValue(event) {
        var editados = this.state.editados;
        var motorista = this.state.motorista;

        if(event.target.id==="formNome"){
            motorista.nome = event.target.value;
            editados.nome = true;
        }
        // else if(event.target.id==="formLogin"){
        //     motorista.login = String(event.target.value).trim();
        //     editados.login = true;}
        this.setState({motorista: motorista});
        this.setState({editados: editados, changed: true});
     }
     //---------------------------------------------------------------
     onValueChangedCheck(args) {
        var editados = this.state.editados;
        var motorista = this.state.motorista;

        motorista.ativo = args.value;                     
        editados.ativo = true;

        this.setState({motorista: motorista});
        this.setState({editados: editados, changed: true});
      }
    //-------------------------------------------------------------------
    showGeralList= (value) => {   
        if(value == PESSOA_TIPO)
            this.props.Modals.showGeralList(this,"Pessoa", PESSOA_TIPO);
        else  if(value == CARTAO_TIPO)
            this.props.Modals.showGeralList(this,"Cartão", CARTAO_TIPO); 
    };
    // //-------------------------------------------------------------------
    // showGeralList= (e) => {   
    //     if(e.target.getAttribute('value') == PESSOA_TIPO)
    //         this.props.Modals.showGeralList(this,"Pessoa", PESSOA_TIPO);
    //     else  if(e.target.getAttribute('value') == CARTAO_TIPO)
    //         this.props.Modals.showGeralList(this,"Cartão", CARTAO_TIPO); 
    // };
    //-----------------------------------------------------
    getList= (geralListModal, tipo) => { 
        this.setState({GeralListModal: geralListModal});  

        if(tipo == PESSOA_TIPO){             
            var instance = new GetPessoa(null, this, this.props.MapaKseg, PESSOA_TIPO, 0);
            instance.get(); 
        }else  if(tipo == CARTAO_TIPO){             
            var instance = new GetCartoes(null, this, this.props.MapaKseg, CARTAO_TIPO);
            instance.get(); 
        }
    };   
    //--------------------------------------
    clickHandler=()=> {
        this.setState(
            {mostrarSenha: !this.state.mostrarSenha},
            () => { this.state.mostrarSenha ? this.setState({type_senha:"text"}) : this.setState({type_senha:"password"})}
          )
    }
    //--------------------------------------
    concluido=()=> {
        this.getMotoristaPessoas();
        this.getMotoristaCartoes();
        this.setState({salvando:false, changed:false });            
        this.state.MotoristasGrid.setMotorista(this.state.motorista);
    }
    //--------------------------------------
    validar=()=> {
        let valido = true;

        if(this.state.motorista.nome.trim()==="" && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Nome!");
        }

        if(this.state.motorista.pessoas.length==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe a Pessoa!");
        }        

        if(valido==true){
            this.setState({salvando:true, changed:true});
            var instance = new PostMotoristaCad(null, this, this.props.MapaKseg);
            instance.post(); 
        }
    }
    //-------------------------
    render(){
        return(
            <div className="drag10">
                <Draggable  handle="strong"  >                
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={600}
                            height={this.state.height_modal}
                            onAnimationEnd={()=>this.desativarModal()}
                            >

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                {this.state.load ?
                                        <Spinner className="centro3" animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    :
                                        <div style={{height: this.state.height_modal, overflow:'hidden'}}>
                                            <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0">
                                                {/*------------------------------------ */}
                                                <Form>                                                       
                                                    <Form.Group className="mb-1" as={Row} controlId="formNome">
                                                        <Form.Label column sm={3}>
                                                            Nome
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-1" >                                                
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>{this.state.motorista.motorista_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.motorista.nome}  onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>     
                                                    
                                        

                                                    {/* ----------------Tab---------------------------------------------------- */}
                                                    <Card>
                                                        <Card.Header>
                                                            <Nav variant="tabs" defaultActiveKey="#Pessoa">
                                                                <Nav.Item >
                                                                    <Nav.Link  data-toggle="tab" href="#Pessoa">Pessoa</Nav.Link>
                                                                </Nav.Item>      
                                                                <Nav.Item >
                                                                    <Nav.Link  data-toggle="tab" href="#Cartao">Cartão</Nav.Link>
                                                                </Nav.Item>                                                                                                                               
                                                            </Nav>
                                                        </Card.Header>

                                                        <div class="tab-content">
                                                            <Card.Body id="Pessoa" className="tab-pane active">
                                                                <MotoristaPessoasGrid  
                                                                    objeto={this}
                                                                    height_grid={this.state.height_grid} 
                                                                    MapaKseg={this.props.MapaKseg} 
                                                                    Modals={this.props.Modals}/>                                                                                                                                   
                                                            </Card.Body>      

                                                            <Card.Body id="Cartao" className="tab-pane fade">
                                                                <MotoristaCartoesGrid  
                                                                    objeto={this}
                                                                    height_grid={this.state.height_grid} 
                                                                    MapaKseg={this.props.MapaKseg} 
                                                                    Modals={this.props.Modals}/>                                                                                                                                   
                                                            </Card.Body>                                                  
                                                                                                                                                                                     
                                                        </div>
                                                    </Card>                                                                                                           

                                                </Form>
                                                {/* ----------------------------------- */}
                                            </CustomScroll>


                                            <Modal.Footer >
                                                <div className="align_bottom_right">
                                                    <Button disabled={!this.state.changed} onClick={this.validar}>
                                                        {this.state.salvando
                                                            ? <Spinner size="sm"  as="span" animation="grow" role="status" aria-hidden="true"/>                                                                
                                                        :
                                                        <div>
                                                            <img src={require('../img/checkbold24.png')}/> Salvar 
                                                        </div>
                                                        }                                                                
                                                    </Button>
                                                </div> 
                                            </Modal.Footer>

                                        </div>
                                    }
                                </div>
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>        
        );
    }
}