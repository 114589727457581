import React, {Component} from 'react';
import {Modal, ListGroup, Spinner, Button, Navbar, Nav, Form, FormControl, Container, Row, Col, Tab} from 'react-bootstrap'; 
import '../estilo.css';
import {CabecalhoModal} from '../CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import {VeiculosGrid} from '../datagrid/veiculos_grid';
import {UsuariosGrid} from '../datagrid/usuarios_grid';
import {ClienteGrid} from '../datagrid/cliente_grid';
import {RotaGrid} from '../datagrid/rota_grid';
import {LocalGrid} from '../datagrid/local_grid';
import {FilialGrid} from '../datagrid/filial_grid';
import {MunicipioGrid} from '../datagrid/municipio_grid';
import {PessoaGrid} from '../datagrid/pessoa_grid';
import {GrupoGrid} from '../datagrid/grupo_grid';
import {MotoristasGrid} from '../datagrid/motoristas_grid';
import {CartaoGrid} from '../datagrid/cartao_grid';

 //--------------------------------  

export class CadastroModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            btnPesquisar:false,
            veiculo_id:null,
            placa:null,
            veiculo_temperaturas_tab:false,
            MapaKseg: props.MapaKseg,
            animation: "zoom",
            minimizar:false,
            drag:"drag8",
            width:1200,
            height:600,
            // grid_height:'400px',
            activeKey:"veiculo",
            height_grid:0,
            menu_list:[]
        };
        this.now = new Date();
      }    
    //--------------------------
    async componentDidMount() {
        this.setState({height_grid: this.state.height-120});
        let menu_list = [];

        if(this.props.MapaKseg.state.pessoa_id===1){
            menu_list = [{
                'ID': 1,
                'descricao': 'Veículo',
                'key':'veiculo'
            }, {
                'ID': 2,
                'descricao': 'Usuário',
                'key':'usuario'
            },{
                'ID': 3,
                'descricao': 'Grupo',
                'key':'grupo'
            }, {
                'ID': 4,
                'descricao': 'Pessoa',
                'key':'pessoa'
            },{
                'ID': 5,
                'descricao': 'Cliente',
                'key':'cliente'
            }, {
                'ID': 6,
                'descricao': 'Rota',
                'key':'rota'
            }, {
                'ID': 7,
                'descricao': 'Filial',
                'key':'filial'
            }, {
                'ID': 8,
                'descricao': 'Município',
                'key':'municipio'
            }, {
                'ID': 9,
                'descricao': 'Local',
                'key':'local'
            }, {
                'ID': 10,
                'descricao': 'Motorista',
                'key':'motorista'
            }, {
                'ID': 11,
                'descricao': 'Cartão',
                'key':'cartao'
              }                 
            ];
        }else if(this.props.MapaKseg.state.gerente===true){  // 23 - Cersul
            menu_list = [
                {'ID': 5,
                'descricao': 'Cliente',
                'key':'cliente' }    
            ];
        }else{  
            menu_list = [
                {'ID': 99,
                'descricao': ' ',
                'key':' ' }    
            ];
        };

        this.setState({menu_list: menu_list});


        this.props.Modals.setState({cadastroModal:this});

        if(this.props.Modals.state.GridColetaEntrega != null)
            this.props.Modals.state.GridColetaEntrega.visibleFilterrowFalse();
        
        this.setState({loading: false});
    };
     //--------------------
     minimizarModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        this.props.Modals.closeCadastroModal();

        if(this.props.Modals.state.GridColetaEntrega != null)
            this.props.Modals.state.GridColetaEntrega.visibleFilterrow();
    };
    //--------------------
    maximizarModal = () =>{
        var grid_h = String(window.innerHeight - 150);
        this.setState({width: window.innerWidth
                        ,height: window.innerHeight
                        ,drag: "drag0"
                        ,height_grid: grid_h});
    };
    //--------------------
    restoreModal = () =>{
        this.setState({width:1200,  height:720});
        this.setState({ drag: "drag3"
                        ,height_grid: this.state.height-120});                     
    };
    //---------close----------
    fecharModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "zoom"});
        this.setState({minimizar:false});
        this.props.Modals.closeCadastroModal();  

        if(this.props.Modals.state.GridColetaEntrega != null)
            this.props.Modals.state.GridColetaEntrega.visibleFilterrow();
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false){
                if(this.state.minimizar)
                    this.props.Modals.closeCadastroModal();
                else this.props.Modals.desativarCadastroModal(); 
            }
    };
    //-------------------------
    desativarFilterrow = () =>{    
        if(this.props.Modals.state.GridColetaEntrega != null)
            this.props.Modals.state.GridColetaEntrega.visibleFilterrowFalse();
    };
    //---------------------
    setIcone = (tipo_evento_id) =>{    
        switch(tipo_evento_id) {
            case 1:
            return require("../img/location_marker48.png"); 
            case 74:
                return require("../img/marker_truck_open.png");                
            default:
            return require("../img/alert_circle48.png");  
        }
    };
    //--------------------------------------
    handleSelect=(e)=> {
        this.setState({activeKey: e.key});
      }
    //------------------------
    render(){
        var data_hoje = new Date();
        var data_ontem = new Date();
        data_ontem.setHours(data_ontem.getHours() - 1);

        

        function alertClicked(selectedKey) {
            // alert('You clicked the third ListGroupItem');
            alert(selectedKey.target.name );
            // this.props.MapaKseg.toast(selectedKey);
            console.log(" selectedKey: ");
            console.log(selectedKey);
          }
          
        return(
            <div className={this.state.drag} >
                <Draggable   handle="strong"  >                
                    <div >                    
                        <Rodal 
                            // visible={this.props.Modals.state.relPosicoesVeiModalShow}
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={this.state.width}
                            height={this.state.height}
                            onAnimationEnd={()=>this.desativarModal()}
                            >  
                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={true} maximizar={false} titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                    {this.state.loading?
                                        <Form  style={{height: '390px', overflow: 'hidden'}}>
                                            <div className="centro">
                                                <div className="espaco_topo3">
                                                    <Spinner animation="border" />
                                                </div>
                                            </div>                                        
                                        </Form> 
                                        : 
                                        <Container className="padding_modal_left" >
                                            <Tab.Container id="list-group-tabs-example" defaultActiveKey={this.state.menu_list[0].key}  >
                                            {/* defaultActiveKey="veiculo" */}
                                                <Row >
                                                    <Col sm={2}>
                                                        <Nav variant="pills" className="flex-column">
                                                            <CustomScroll  scrollTo={this.state.scrollOffset}  scrollBarRadius="0" >
                                                                {this.state.menu_list.map((menu) =>
                                                                    <Nav.Item>
                                                                        <Nav.Link eventKey={menu.key}>{menu.descricao+'  '}</Nav.Link>
                                                                    </Nav.Item>                                           
                                                                    )}
                                                            </CustomScroll>
                                                        </Nav>
                                                    </Col>
                                                    <Col sm={10}>
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="veiculo">
                                                                <VeiculosGrid titulo={"Veículo"} height_grid={this.state.height_grid} MapaKseg={this.props.MapaKseg} />
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="usuario">
                                                                <UsuariosGrid titulo={"Usuário"} height_grid={this.state.height_grid} MapaKseg={this.props.MapaKseg} />
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="grupo">
                                                                <GrupoGrid titulo={"Grupo"} height_grid={this.state.height_grid} MapaKseg={this.props.MapaKseg} />
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="pessoa">
                                                                <PessoaGrid titulo={"Pessoa"} height_grid={this.state.height_grid} MapaKseg={this.props.MapaKseg} />
                                                            </Tab.Pane>                                                                    
                                                            <Tab.Pane eventKey="cliente">
                                                                <ClienteGrid titulo={"Cliente"} height_grid={this.state.height_grid} MapaKseg={this.props.MapaKseg} />
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="rota">
                                                                <RotaGrid titulo={"Rota"} height_grid={this.state.height_grid} MapaKseg={this.props.MapaKseg} />
                                                            </Tab.Pane>   
                                                            <Tab.Pane eventKey="filial">
                                                                <FilialGrid titulo={"Filial"} height_grid={this.state.height_grid} MapaKseg={this.props.MapaKseg} />
                                                            </Tab.Pane>   
                                                            <Tab.Pane eventKey="municipio">
                                                                <MunicipioGrid titulo={"Município"} height_grid={this.state.height_grid} MapaKseg={this.props.MapaKseg} />
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="local">
                                                                <LocalGrid titulo={"Localização"} height_grid={this.state.height_grid} MapaKseg={this.props.MapaKseg} />
                                                            </Tab.Pane>                                                                                      
                                                            <Tab.Pane eventKey="motorista">
                                                                <MotoristasGrid titulo={"Motorista"} height_grid={this.state.height_grid} MapaKseg={this.props.MapaKseg} />
                                                            </Tab.Pane>  
                                                            <Tab.Pane eventKey="cartao">
                                                                <CartaoGrid titulo={"Cartão"} height_grid={this.state.height_grid} MapaKseg={this.props.MapaKseg} />
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </Col>
                                                </Row>
                                            </Tab.Container>                                                                                         
                                        </Container>          
                                    }                                         
                                </div>                        
                            </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>

            
        );
    }
}    