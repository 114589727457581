import React from 'react';
import './estilo.css';

// import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { Router, Route } from "react-router";
import Home from './home'
// import {Home2} from './home2'
import MapaRastreio from './mapa_rastreio'


class App extends React.Component {

 //------------------------------------
 async componentDidMount() {
//   window.addEventListener('beforeunload', this.beforeunload.bind(this));
}
//-----------------
async componentWillUnmount() {
//   window.removeEventListener('beforeunload', this.beforeunload.bind(this));
//   localStorage.clear();
}
//-----------------
beforeunload(e) {
  localStorage.clear();
  // if (this.props.dataUnsaved) {
  //   e.preventDefault();
  //   e.returnValue = true;
  // }
}
//-----------------
  render() {
      return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/home2" element={<Home2 />} /> */}
            <Route path="/MapaRastreio/:id/:token" element={<MapaRastreio />} />
            {/* <Route exact path="/MapaRastreio/:id" component={MapaRastreio} /> */}
            {/* <Route path="/MapaRastreio/:id" component={<MapaRastreio />} /> */}
            {/* <Route exact path="/MapaRastreio/:id" render={(props) => <MapaRastreio globalStore={globalStore} {...props} /> } /> */}
            {/* <Route exact path="/MapaRastreio/:id" render={(props) => ( <MapaRastreio id={props.match.params.id}/>   )} /> */}
              {/* <Route path="/" exact>
                  <MapaRastreio />
              </Route> */}
     
          </Routes>         
        </BrowserRouter>
      );
    }
  }

export default App;
