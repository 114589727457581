import React from "react";
import Routes2 from './routes2';
// import { isAuthenticated } from './auth';

import MapaKseg from './mapaKseg';
import LoginQrcode from './loginQrcode';
import Login from './login';
import { isAuthenticated } from './auth';
import { withRouter } from "react-router";
// import { withRouter } from 'react-router-dom';

// const App = () => <Routes />; // Como não tem estado, foi transformado em um stateless component
                             // para diminuir o codigo, e mais fácil ver o que esta acontecendo

export default class Home extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            autenticado:false,
            botaoNovoQrCode:false,
            qrcodeload:true,
            authload:true,

            login:'',
            pass:'',

            pessoa_id:null,
            gerente:null,
            usuario_id:null,
            nome:null,
            grupo_id:null,
        };
      }
    //--------------   
    sleep (milliseconds) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
      }
    //---------------
    isAuth = async() => {
        console.log("isAuth Autenticado: "+localStorage.getItem('autenticado'))        
    
        if( !this.state.botaoNovoQrCode && parseInt(localStorage.getItem('autenticado'))===0){
            for (var i=0; i < 5; i++) {
                console.log("i:"+i);
                // await this.sleep(5000)
                isAuthenticated();
                if(localStorage.getItem('autenticado') !== null){
                    console.log("isAuth Autenticado: "+localStorage.getItem('autenticado'))
                    console.log("state1 Autenticado: "+this.state.autenticado)
                    // this.setState({autenticado:true});
                    if (parseInt(localStorage.getItem('autenticado'))===1){
                        this.setState({autenticado:true});
                        this.setState({login: localStorage.getItem('l')});
                        this.setState({pass: localStorage.getItem('p')});
                        localStorage.setItem('l', '')
                        localStorage.setItem('p', '')       

                        return true
                        // return(this.Routes())
                    }
                    //  else return false    
                }        
                await this.sleep(3000)
            }
            this.setState({botaoNovoQrCode:true})
            this.setState({qrcodeload:true})
        }
        return false;
    };
    //-------------- 
    loginPage = () => {
        localStorage.setItem('autenticado', 0);
        localStorage.setItem('hash_kseg_web', 0);
        // this.isAuth();
        return(
            <Login 
                onAuthloadChange={this.onChangeAuthLoad}  />
            // <LoginQrcode
            //     botaoNovoQrCode={this.state.botaoNovoQrCode} 
            //     qrcodeload={this.state.qrcodeload} onQrcodeloadChange={this.onChangeQrcodeload}  />
        );
    }
    //---------------
    onChangeQrcodeload = (newBool) => { this.setState({ qrcodeload: newBool });}
    //---------------
    onChangeAuthLoad = (newBool, pessoa_id, usuario_id, nome, grupo_id, login, senha, tema_mapa, nome_social, mapa_ativo, modo_mapa, gerente) => {
        localStorage.setItem('grupo_id', grupo_id);
        console.log("**********state1 Autenticado: "+newBool)
        this.setState({ autenticado: newBool});
           this.setState({  pessoa_id:pessoa_id, usuario_id:usuario_id, 
                            nome:nome, grupo_id:grupo_id, login:login, senha:senha, tema_mapa:tema_mapa, nome_social:nome_social
                            , mapa_ativo:mapa_ativo, modo_mapa:modo_mapa, gerente: gerente
                        });
    }
    //--------------
    render(){
        // function onChangeQrcodeload(newBool) {   this.setState({ qrcodeload: newBool });}
        // return this.state.rota
        return(
            <div>
                {this.state.autenticado ? 
                    <MapaKseg pessoa_id={this.state.pessoa_id} gerente={this.state.gerente}
                        usuario_id={this.state.usuario_id} nome={this.state.nome}
                        grupo_id={this.state.grupo_id} login={this.state.login} senha={this.state.senha} 
                        tema_mapa={this.state.tema_mapa}
                        nome_social={this.state.nome_social}
                        mapa_ativo={this.state.mapa_ativo}
                        modo_mapa={this.state.modo_mapa}
                         />
          
                : this.loginPage()}
            </div>
        );
    }
}

// export default Home2;
// export default withRouter(Home2);
