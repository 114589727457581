import  {Component} from 'react';
import api from '../api';

export default class PostCartaoCad extends Component{
    constructor(props, objeto, MapaKseg ){
        super(props);
        this.state = {
             objeto: objeto,
             MapaKseg: MapaKseg
       }
    }
    //------------------------------------------------
    post = async () => {
        let motorista_cartao_idd =0;
        try {                    
            let editados = this.state.objeto.state.editados;
            let cartao = this.state.objeto.state.cartao;
            motorista_cartao_idd = cartao.motorista_cartao_id;

            let response = null;
            let responseJson = null;
            let body =null;            


            if(editados.codigo || editados.pessoa || editados.motorista){

                body = new FormData();
                body.append('token', localStorage.getItem('token'));
                body.append('motorista_cartao_id', cartao.motorista_cartao_id);

                if(editados.codigo) 
                    body.append('codigo', cartao.codigo);
                if(editados.pessoa) 
                    body.append('pessoa_id', cartao.pessoa_id); 
                if(editados.motorista) 
                    body.append('motorista_id', cartao.motorista_id);        

                response = await api.post('/cadastro_api/postCartao', body);

                if (response.data !== null  &&  response.status !== 403){
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json); 
                    motorista_cartao_idd = resposta.motorista_cartao_id; 
                }

            }
             

        } finally {      
            let cartao = this.state.objeto.state.cartao;
            cartao.motorista_cartao_id = motorista_cartao_idd;
            this.state.objeto.setState({ cartao: cartao   },
               () => { this.state.objeto.concluido()  });  

            }
    };
}