import  {Component} from 'react';
import api from '../api';

export default class GetMotoristas extends Component{
    constructor(props, objeto, MapaKseg, tipo, filtro=''){
        super(props);
        this.state = {
             list: [],
             loading:true,
             objeto: objeto,
             motorista:null,
             tipo: tipo,
             MapaKseg: MapaKseg,
             filtro: filtro
       }
    }
    //------------------------------------------------
    get = async () => {
        try {        
            let body = new FormData()
            body.append('pessoa_id', this.state.MapaKseg.state.pessoa_id);            
            body.append('token', localStorage.getItem('token'))

            if (this.state.filtro !== '') body.append('nome', this.state.filtro);            

            const response = await api.post('/cadastro_api/getMotoristas', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let motorista = this.state.motorista;
                let list = this.state.list;                

                // ToastStore.success("resposta.length: "+ String(resposta.length));
                for (var i = 0; i < resposta.length; i++) {                        
                    motorista = {    
                        motorista_id: resposta[i].motorista_id,
                        nome: resposta[i].nome,
                        pessoa_id: resposta[i].pessoa_id,
                        nome_fantasia: resposta[i].nome_fantasia,
                        selecionada: false,
                        index_scroll: (i+2)*25,
                    };


                    list.push(motorista);
                    this.setState({list: list});
                }

                this.setState({loading: false});
                
            }
        } finally {      
            this.state.objeto.setListMotoristas(this.state.list, this.state.tipo);
            // this.state.objeto.setState({loading: false});
            // this.state.MapaKseg.toast(this.state.list.length);
            }
    };
    //--------------------------
}