import React, {Component} from 'react';
import {Modal, Image, Spinner, Button, Form, Row,Col,Card,Nav,InputGroup,FormControl} from 'react-bootstrap';
import '../estilo.css';
import {CabecalhoModal} from '../CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import GetPessoa from '../services/get_pessoa';
import GetLocais from '../services/get_locais';
import PostCartaoCad from '../services/post_cartao_cad';
import GetMotoristas from '../services/get_motoristas';

const PESSOA =1;
const MOTORISTA = 2;

export class CartaoCadModal extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            animation: "zoom",
            list:[],
            load:true,
            minimizar:false,
            changed:false,
            cartao:null,

            GeralListModal:null,            
            cartaoGrid:null,

            height_modal:330,
            height_grid:0,
            items:[],
            editados: {},

            salvando:false,            
        };

         // -------------------------------
        this.dataGridRef = React.createRef();
    
        this.selectedRowsData = [];

        this.getSelectedData = () => {
            this.selectedRowsData = this.dataGrid.getSelectedRowsData();
            this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            });
        }
      }
    //---------------------------------------------------------------
    get dataGrid() {
        return this.dataGridRef.current.instance;
    }
    //-------------------------------------------------------------------
    async componentDidMount() {
        // INICIALIZAR VARIÁVEIS

        this.setState({cartaoGrid: this.props.objeto});

        let editados = {
              codigo:false
            ,pessoa: false
            ,motorista: false
             };

        let cartao = this.props.obj_selected;

        this.setState({
            cartao: cartao       
                ,editados: editados        
                , height_grid: this.state.height_modal-420},
            () => { 
                if(cartao.motorista_cartao_id != 0){
                    // this.getUsuariosGrupo()
                    // this.getTokensUsuario()
                }
                this.setState({load:false}) }
          )
    }; 
    //----------------------------------------------------------------
    getCartao = () =>{
        var instance = new GetLocais(null, this, this.props.MapaKseg, 0, this.state.cartao.motorista_cartao_id );
        instance.get();   
    }; 
    //----------------------------------------------------------------
    getPessoa = () =>{
        var instance = new GetPessoa(null, this, this.props.MapaKseg,0, this.state.cartao.motorista_cartao_id );
        instance.get();   
    };  
    //----------------------------------------------------------------
    GetMotoristas = () =>{
        var instance = new GetPessoa(null, this, this.props.MapaKseg,0, this.state.cartao.motorista_cartao_id );
        instance.get();   
    }; 
    //--------------------------------------------------------------------
    setListCartao = (_list) => {
        let cartao = this.state.cartao;
        let obj = _list[0];

        cartao.motoristacartao_id = obj.motorista_cartao_id;
        cartao.codigo = obj.codigo;
        cartao.pessoa_id = obj.pessoa_id;
        cartao.nome_social = obj.nome_social;

        this.setState({cartao: cartao});
    } 
    //------------------------------------------------------------------------
    minimizarModal = () =>{
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        this.props.Modals.closeCartaoCadModal();  
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeCartaoCadModal();
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarCartaoCadModal();
    };    
    //------------------------------------------------------
    updateInputValue(event) {
        var editados = this.state.editados;
        var cartao = this.state.cartao;

        if(event.target.id==="formCodigo"){
            cartao.codigo = event.target.value;
            editados.codigo = true;
        }else 
        if(event.target.id==="formPessoa"){
            cartao.pessoa_id = event.target.value;
            editados.pessoa = true;
        }else 
        if(event.target.id==="formMotorista"){
            cartao.motorista_id = event.target.value;
            editados.motorista = true;
        }

        this.setState({cartao: cartao});
        this.setState({editados: editados, changed: true});
     }
         //-------------------------------------------------------------------
    showGeralList= (e) => {   
        if(e.target.getAttribute('value') == PESSOA)
            this.props.Modals.showGeralList(this,"Pessoa", PESSOA);   
        else if(e.target.getAttribute('value') == MOTORISTA)
            this.props.Modals.showGeralList(this,"Motoristas", MOTORISTA);          
    };
    //-----------------------------------------------------
    getList= (geralListModal, tipo) => { 
        this.setState({GeralListModal: geralListModal});  

        if(tipo == PESSOA){             
            var instance = new GetPessoa(null, this, this.props.MapaKseg, PESSOA, 0);
            instance.get(); 
        }else if(tipo == MOTORISTA){             
                    var instance = new GetMotoristas(null, this, this.props.MapaKseg, MOTORISTA);
                    instance.get(); 
                }
    };
    //-------------------------------------------------------------
    setListPessoa =  (_list, tipo) => {  
        let array =[]
        let sel=false;
        for (var i = 0; i < _list.length; i++) { 
            if(this.state.cartao.pessoa_id == _list[i].pessoa_id) sel=true;
            else sel=false;

            array.push({
                index: _list[i].index
                , id: _list[i].pessoa_id
                , descricao: _list[i].nome_social
                , selecionada: sel
                , index_scroll: 0});
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //--------------------------------------------------------------
    setListMotoristas = (_list, tipo) => {  
        let array =[]
        for (var i = 0; i < _list.length; i++) { 
            array.push({id: _list[i].motorista_id
                , descricao: _list[i].nome + " ("+_list[i].pessoa_id+" - "+_list[i].nome_fantasia+")"
                , pessoa_id: _list[i].pessoa_id
                , selecionada: _list[i].selecionada
                , index_scroll: _list[i].index_scroll});
        }
        this.state.GeralListModal.setList(array, tipo);
    };      
    //---------------------------------------------------
    setObjeto= (objeto, tipo) => { 
        let editados =this.state.editados;

        if(tipo == PESSOA){
            let cartao = this.state.cartao;
            cartao.pessoa_id = objeto.id;
            cartao.pessoa_nome = objeto.descricao;                        
            editados.pessoa =  true;            
            this.setState({cartao: cartao});                        

        }else if(tipo == MOTORISTA){
            let cartao = this.state.cartao;
            cartao.motorista_id = objeto.id;
            cartao.motorista_nome = objeto.descricao;
            cartao.edit_motorista=true;
            editados.motorista =  true;
            this.setState({cartao: cartao});
        }
        this.setState({editados: editados });
        this.setState({changed:true});
    };
    //--------------------------------------
    concluido=()=> {
        // this.getCartao();
        // this.state.cartaoGrid.getList();
        this.setState({salvando:false, changed:false });     
        this.state.cartaoGrid.setCartao(this.state.cartao);  
    }
    //--------------------------------------
    validar=()=> {
        let valido = true;

        if(String(this.state.cartao.codigo).trim()==="" && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Código do Cartão!");
        }

        if(this.state.cartao.pessoa_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe a Pessoa!");
        }    
    
        if(valido==true){
            this.setState({salvando:true, changed:true});
            var instance = new PostCartaoCad(null, this, this.props.MapaKseg);
            instance.post(); 
        }
    }
    //-------------------------
    render(){
        return(
            <div className="drag1">
                <Draggable  handle="strong"  >                
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={500}
                            height={this.state.height_modal}
                            onAnimationEnd={()=>this.desativarModal()}
                            >

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                {this.state.load ?
                                        <Spinner className="centro3" animation="border" role="status">
                                            <span className="sr-only"></span>
                                        </Spinner>
                                    :
                                        <div style={{height: this.state.height_modal, overflow:'hidden'}}>
                                            <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0">
                                                {/*------------------------------------ */}
                                                <Form>                                                       
                                                    <Form.Group className="mb-1" as={Row} controlId="formCodigo">
                                                        <Form.Label column sm={2}>
                                                            Cartão
                                                        </Form.Label>
                                                        <Col sm={6}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>{this.state.cartao.motorista_cartao_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.cartao.codigo}  onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>        
                                                   
                                                    <Form.Group className="mb-1" as={Row} controlId="formPessoa">
                                                        <Form.Label column sm={2}>
                                                            Pessoa
                                                        </Form.Label>
                                                        <Col sm={9}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formPessoaBt" value={PESSOA} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                        <Image value={PESSOA} src={require('../img/text_box_search48.png')} width='20'/>
                                                                    </Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text onChange={this.updateInputValue.bind(this)} >{this.state.cartao.pessoa_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control disabled aria-describedby="basic-addon1" value={this.state.cartao.pessoa_nome}  />
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>    

                                                    <Form.Group as={Row} controlId="formMotorista">
                                                        <Form.Label column sm={2}>
                                                            Motorista
                                                        </Form.Label>
                                                        <Col sm={9}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formMotoristaBt" value={MOTORISTA} onClick={this.showGeralList} variant="outline-secondary" >
                                                                        <Image value={MOTORISTA} src={require('../img/text_box_search48.png')} width='20'/>
                                                                    </Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>{this.state.cartao.motorista_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control  aria-describedby="basic-addon1" value={this.state.cartao.motorista_nome}  disabled="true"> 

                                                                </Form.Control>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>                                                                                                                                                          

                                                </Form>
                                                {/* ----------------------------------- */}
                                            </CustomScroll>


                                            <Modal.Footer >
                                                <div className="align_bottom_right">
                                                    <Button disabled={!this.state.changed} onClick={this.validar}>
                                                        {this.state.salvando
                                                            ? <Spinner size="sm"  as="span" animation="grow" role="status" aria-hidden="true"/>                                                                
                                                        :
                                                        <div>
                                                            <img src={require('../img/checkbold24.png')}/> Salvar 
                                                        </div>
                                                        }                                                                
                                                    </Button>
                                                </div> 
                                            </Modal.Footer>

                                        </div>
                                    }
                                </div>
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>        
        );
    }
}


//   6920998
//   1216268800
//   12726839000
//   12405842888