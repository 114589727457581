import React, {Component} from 'react';
import {Modal, Form, Button as Button_bootstrap, Card, Row, Col, Dropdown, DropdownButton} from 'react-bootstrap';
import '../estilo.css';
import SelectBox from 'devextreme-react/select-box';


export  class AnotacaoModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bottomSheet:true, 
            showSheet:true,
            bloqueado_salvar: true,
            usuario_anotacao : {'color': "#E8D979"} 
        };
      }   
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeAnotacaoModal();
    };
    //------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarAnotacaoModal();
    };
    //--------------------------
    async componentDidMount() {
        this.setState({usuario_anotacao: this.props.objeto.state.anotacao_selecionada});
        
        document.getElementById("ControlTextArea1").focus();
        
    };
    //------------------------
    okClick = ()=>{
        if(String(this.state.usuario_anotacao.titulo_anotacao).length > 200 ) this.props.MapaKseg.toastError("O título tem "+String(this.state.usuario_anotacao.titulo_anotacao.length)+" caracteres, ultrapassando o tamanho limite de 200 caracteres!");
        else{
            if(String(this.state.usuario_anotacao.anotacao).length > 1000 ) this.props.MapaKseg.toastError("A anotação tem "+String(this.state.usuario_anotacao.anotacao.length)+" caracteres, ultrapassando o tamanho limite de 1.000 caracteres!!");
                else{
                    if(String(this.state.usuario_anotacao.titulo_anotacao).length === 0 ) this.props.MapaKseg.toastError("O título precisa ser preenchido! "+String(String(this.state.usuario_anotacao.anotacao).length));
                    else{
                        if(String(this.state.usuario_anotacao.anotacao).length === 0 ) this.props.MapaKseg.toastError("A anotação precisa ser preenchida!");
                            else{
                                if(this.state.usuario_anotacao.usuario_anotacao_id === 0)
                                    this.props.objeto.postAnotacao(this.state.usuario_anotacao.titulo_anotacao, this.state.usuario_anotacao.anotacao, this.state.usuario_anotacao.color);
                                else this.props.objeto.postUpdateAnotacao(this.state.usuario_anotacao.titulo_anotacao, this.state.usuario_anotacao.anotacao, this.state.usuario_anotacao.color);

                                this.fecharModal();
                            }
                        }
                }
        }
    };
    //-------------------------
    setTitulo(event) {
        if(String(event.target.value).length > 200 ) this.props.MapaKseg.toastError("O título tem "+String(this.state.usuario_anotacao.titulo_anotacao.length)+" caracteres, ultrapassando o tamanho limite de 200 caracteres!");

        // this.setState({titulo_anotacao: event.target.value});
        let usuario_anotacao = this.state.usuario_anotacao;
        usuario_anotacao.titulo_anotacao = event.target.value;
        this.setState({usuario_anotacao: usuario_anotacao});
    }
    //-------------------------
    setTextoAnotacao(event) {
        if(String(event.target.value).length > 1000 ) this.props.MapaKseg.toastError("A anotação tem "+String(this.state.usuario_anotacao.anotacao.length)+" caracteres, ultrapassando o tamanho limite de 1.000 caracteres!!");   
        
        // this.setState({anotacao: event.target.value});
        let usuario_anotacao = this.state.usuario_anotacao;
        usuario_anotacao.anotacao = event.target.value;
        this.setState({usuario_anotacao: usuario_anotacao});
    }    
     //--------------------------
     setColorAnotacao = (color) =>{
        let usuario_anotacao = this.state.usuario_anotacao;
        usuario_anotacao.color = color;
        this.setState({usuario_anotacao: usuario_anotacao});
    } 
    //-------------------------  <img src={this.props.comando.icone} /> {this.props.comando.descricao}
    render(){
        return(
            <div className="splashscreen-modal1">
                <Modal  centered
                    {...this.props}
                    
                    aria-labelledby="example-custom-modal-styling-title"
                    scrollable="True"
                    dialogClassName="temperatura-modal2"    
                    >
                    <div >
                        <strong className="cursor">
                            <div className="cabecalho"> 
                            <label className="titulo2" > {this.state.usuario_anotacao.titulo_anotacao} </label>
                                {/* <label className="titulo2" > Aviso! </label> */}
                            </div>
                        </strong>
                            
                        <Modal.Body>       
                            <Card 
                                // bg={this.state.usuario_anotacao.color} 
                                style={{ width: '28rem', backgroundColor: this.state.usuario_anotacao.color }}
                                // text={this.state.usuario_anotacao.color === 'light' ? 'dark' : 'white'}
                                className="mb-2">

                                <Card.Header>
                                    <Row className="g-2">
                                        <Col md>
                                            {this.state.usuario_anotacao.data_anotacao}
                                        </Col>
                                        <Col md>
                                            <DropdownButton  variant="outline-secondary"
                                                    title={<img className="thumbnail-image" 
                                                                src={require('../img/anotacao/'+this.state.usuario_anotacao.color+'.png')}
                                                                width="20" height="20"
                                                                alt="user pic"
                                                            />  } >  

                                                <Dropdown.Item onClick={()=>this.setColorAnotacao("#86b4f7")} > <img src={require('../img/anotacao/#86b4f7.png')} width="20" height="20" /> </Dropdown.Item>
                                                <Dropdown.Item onClick={()=>this.setColorAnotacao("#6ee097")}> <img src={require('../img/anotacao/#6ee097.png')} width="20" height="20" /> </Dropdown.Item>
                                                <Dropdown.Item onClick={()=>this.setColorAnotacao("#F095A1")}> <img src={require('../img/anotacao/#F095A1.png')} width="20" height="20" /></Dropdown.Item>
                                                <Dropdown.Item onClick={()=>this.setColorAnotacao("#E8D979")}> <img src={require('../img/anotacao/#E8D979.png')} width="20" height="20" /> </Dropdown.Item>
                                                <Dropdown.Item onClick={()=>this.setColorAnotacao("#b78ff2")}> <img src={require('../img/anotacao/#b78ff2.png')} width="20" height="20" /> </Dropdown.Item>
                                                <Dropdown.Item onClick={()=>this.setColorAnotacao("#f28fe7")}> <img src={require('../img/anotacao/#f28fe7.png')} width="20" height="20" /> </Dropdown.Item>
                                                <Dropdown.Item onClick={()=>this.setColorAnotacao("#F8F9FA")}> <img src={require('../img/anotacao/#F8F9FA.png')} width="20" height="20" /> </Dropdown.Item>
                                                <Dropdown.Item onClick={()=>this.setColorAnotacao("#a0a2a3")}> <img src={require('../img/anotacao/#a0a2a3.png')} width="20" height="20" /> </Dropdown.Item>
                                            </DropdownButton>

                                          
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="ControlTextArea1"> 
                                            <Card.Title>                                   
                                                <Form.Control as="textarea" plaintext defaultValue={this.state.usuario_anotacao.titulo_anotacao} placeholder="Título" rows={1} onChange={this.setTitulo.bind(this)} />                                        
                                            </Card.Title>
                                            <Card.Text>                                 
                                                <Form.Control as="textarea" plaintext defaultValue={this.state.usuario_anotacao.anotacao} placeholder="Anotação..." rows={6} onChange={this.setTextoAnotacao.bind(this)} />                                                               
                                            </Card.Text>
                                        </Form.Group>
                                    </Form>
                                </Card.Body>
                            </Card>
                            <br />

                           
                        </Modal.Body>
                        
                    </div>


                    <Modal.Footer>
                        {this.props.cancelar ?
                            <Button_bootstrap className="left" onClick={this.props.onHide}>Cancelar</Button_bootstrap> : ""
                        }
                        <Button_bootstrap onClick={this.okClick}> Salvar </Button_bootstrap>                
                    </Modal.Footer>

                </Modal>

            </div>
        );
    }
}