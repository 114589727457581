import  {Component} from 'react';
import api from '../api';

export default class PostMotoristaCad extends Component{
    constructor(props, objeto, MapaKseg ){
        super(props);
        this.state = {
             objeto: objeto,
             MapaKseg: MapaKseg
       }
    }
    //------------------------------------------------
    post = async () => {
        let motorista_idd= 0;
        try {        
            let editados = this.state.objeto.state.editados;
            let motorista = this.state.objeto.state.motorista;
            motorista_idd = motorista.motorista_id;

            let response = null;
            let responseJson = null;
            let body =null;            

            if(editados.nome ){

                body = new FormData();
                body.append('token', localStorage.getItem('token'));
                body.append('motorista_id', motorista.motorista_id);

                if(editados.nome) body.append('nome', motorista.nome);

                // response = await api.post('/cadastro_api/postUsuarioPessoa', body);
                // response = await api.post('/teste_api/getMotoristaCartao', body);
                response = await api.post('/cadastro_api/postMotorista', body);

                if (response.data !== null  &&  response.status !== 403){
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json); 
                    motorista_idd = resposta.motorista_id; 
                }

            }
             
            if(motorista_idd != 0){
                if(editados.pessoas==true){
                    let pessoas = motorista.pessoas.filter((pessoa,index) =>{ return pessoa.operacao !== 0;});
                    
                    if(editados.pessoas) pessoas = motorista.pessoas.filter((pessoa,index) =>{ return pessoa.operacao !== 0;});

                    if(pessoas.length >0){
                        pessoas.sort((a,b) => a.operacao - b.operacao);
                        let list_json =[];

                        for (var i = 0; i < pessoas.length; i++) {   
                            list_json.push(JSON.stringify({
                                pessoa_id: pessoas[i].pessoa_id
                                , motorista_id: motorista_idd
                                , operacao: pessoas[i].operacao }));
                        }

                        body = new FormData();
                        body.append('token', localStorage.getItem('token'));
                        body.append('list_json', list_json);

                        // response = await api.post('/cadastro_api/postUsuarioGrupos________', body);
                        response = await api.post('/cadastro_api/postMotoristaPessoas', body);

                        if (response.data !== null  &&  response.status !== 403){
                        responseJson = JSON.stringify(response.data)
                        var req = { json: responseJson };
                        var resposta = JSON.parse(req.json); 
                            // console.log(resposta.poligono_id);                            // var coleta_id = resposta.coleta_id;    
                            // nova_coleta.coleta_id = resposta.resposta; 
                        // this.state.MapaKseg.toast(" "+String(resposta.resposta ));

                        }
                    }
                }

                if(editados.cartoes){                    
                    let cartoes = motorista.cartoes.filter((cartao,index) =>{ return cartao.operacao !== 0;});

                    if(cartoes.length >0){
                        cartoes.sort((a,b) => a.operacao - b.operacao);
                        let list_json =[];
                        for (var i = 0; i < cartoes.length; i++) {   
                            list_json.push(JSON.stringify({
                                motorista_cartao_id: cartoes[i].motorista_cartao_id
                                , motorista_id: motorista_idd
                                , pessoa_id: cartoes[i].pessoa_id
                                , codigo: cartoes[i].codigo
                                , operacao: cartoes[i].operacao }));
                        }

                        body = new FormData();
                        body.append('token', localStorage.getItem('token'));
                        body.append('list_json', list_json);

                        response = await api.post('/cadastro_api/postMotoristaCartoes', body);
                    }
                }   
            }

        } finally {      
            let motorista = this.state.objeto.state.motorista;
            motorista.motorista_id = motorista_idd;
            this.state.objeto.setState({motorista: motorista});
            this.state.objeto.concluido();
            }
    };
}